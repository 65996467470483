import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { useOptician } from "../optician_loader/OpticianTheme";


export const Headline = (props: PropsWithChildren<{}>) => {
  const optician = useOptician();

  const color = optician?.design?.headlineColor ?? "#000000";

  return (
    <Typography variant="h3" sx={{
      color,
      fontSize: "40px",
      fontWeight: "bold",
      textAlign: "center",
      textTransform: "uppercase"
    }}>{props.children}</Typography>
  )
}