/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    String = 'STRING',
    Number = 'NUMBER'
}

export function FieldTypeFromJSON(json: any): FieldType {
    return FieldTypeFromJSONTyped(json, false);
}

export function FieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldType {
    return json as FieldType;
}

export function FieldTypeToJSON(value?: FieldType | null): any {
    return value as any;
}

