/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    RaffleCode,
    RaffleCodeFromJSON,
    RaffleCodeFromJSONTyped,
    RaffleCodeToJSON,
    RaffleCodePaginatedAllOf,
    RaffleCodePaginatedAllOfFromJSON,
    RaffleCodePaginatedAllOfFromJSONTyped,
    RaffleCodePaginatedAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface RaffleCodePaginated
 */
export interface RaffleCodePaginated {
    /**
     * 
     * @type {number}
     * @memberof RaffleCodePaginated
     */
    recordsTotal?: number;
    /**
     * 
     * @type {Array<RaffleCode>}
     * @memberof RaffleCodePaginated
     */
    data: Array<RaffleCode>;
}

export function RaffleCodePaginatedFromJSON(json: any): RaffleCodePaginated {
    return RaffleCodePaginatedFromJSONTyped(json, false);
}

export function RaffleCodePaginatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RaffleCodePaginated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordsTotal': !exists(json, 'recordsTotal') ? undefined : json['recordsTotal'],
        'data': ((json['data'] as Array<any>).map(RaffleCodeFromJSON)),
    };
}

export function RaffleCodePaginatedToJSON(value?: RaffleCodePaginated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordsTotal': value.recordsTotal,
        'data': ((value.data as Array<any>).map(RaffleCodeToJSON)),
    };
}


