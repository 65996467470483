import { Button, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LegalText } from "../bottom_bar/LegalText";
import { TextDialog } from "../bottom_bar/TextDialog";
import { useApi } from "../common/ApiProvider";
import { CustomWinnerField, Price } from "../gen/api";
import { useOptician } from "../optician_loader/OpticianTheme"


interface RedeemPriceFormProps {
  price: Price;
  code: string;
}

const WinnerTextField = styled(TextField)(() => ({
  margin: "8px"
}))

const RedeemButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  boxShadow: `0 15px 30px ${theme.palette.primary.main}3C`,
  fontSize: "15px",
  fontWeight: "bold",
  height: "56px",
  margin: "auto",
  marginBottom: "40px",
  marginTop: "16px",
  width: "327px"
}));

export const RedeemPriceForm = (props: RedeemPriceFormProps) => {
  const { code } = props;
  const optician = useOptician();
  const api = useApi();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string>("");
  const [givenName, setGivenName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [customWinnerFields, setCustomWinnerFields] = useState<CustomWinnerField[] | null>(null);
  const [customFieldValues, setCustomFieldValues] = useState<string[]>([]);
  const [participationRead, setParticipationRead] = useState(false);
  const [participationOpen, setParticiaptionOpen] = useState(false);

  useEffect(() => {
    api.getCustomWinnerFieldsByOptician({ id: optician!.id }).then(fields => {
      setCustomWinnerFields(fields);
      setCustomFieldValues(fields.map(() => ""));
    }).catch(ex => {
      console.log("Failed to retrieve custom winner fields", ex);
      enqueueSnackbar("Beim Abrufen der Daten ist ein Fehler passiert. Bitte versuchen Sie es später nochmal", { variant: "error" })
    })
  }, [api, optician, setCustomWinnerFields, setCustomFieldValues, enqueueSnackbar]);

  const redeemPrice = useCallback(() => {
    if (!givenName) {
      enqueueSnackbar("Bitte geben Sie Ihren Vornamen an", { variant: "error" });
      return;
    }
    if (!surname) {
      enqueueSnackbar("Bitte geben Sie Ihren Nachnamen an", {  variant: "error" });
      return;
    }
    if (!email) {
      enqueueSnackbar("Bitte geben Sie Ihren E-Mail Addresse an", { variant: "error" });
      return;
    }
    for (let i = 0; i < customFieldValues.length; i++) {
      if (!customFieldValues[i]) {
        const field = customWinnerFields![i];
        if (field.required) {
          enqueueSnackbar(`Bitte füllen Sie das Feld "${field.name}" aus`, { variant: "error" });
          return;
        }
      }
    }
    if (!participationRead) {
      enqueueSnackbar("Bitte lesen und akzeptieren Sie die Teilnahmebedingungen", { variant: "error" });
      return;
    }
    api.redeemCode({
      opticianId: optician!.id,
      code: code!,
      winnerPayload: {
        email,
        givenName,
        surname,
        customFields: customFieldValues.map((value, i) => ({
          name: customWinnerFields![i].name!,
          value
        }))
      }
    }).then(raffleCode => {
      history.push(`/${optician!.slug}/success/${raffleCode.id}`)
    }).catch(async (ex: Response) => {
      if (ex.status === 409 && (await ex.json()).message.startsWith("ALREADY_REDEEMED")) {
        enqueueSnackbar("Dieser Code wurde bereits eingelöst");
        return;
      }
      console.error(ex);
      enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut", { variant: "error" });
    })
  }, [enqueueSnackbar, api, optician, code, email, givenName, surname, customFieldValues, customWinnerFields, history, participationRead]);

  if (!customWinnerFields) {
    return (
      <CircularProgress />
    )
  }

  return (
    <Box>
      <Typography variant="h3" sx={{
        fontSize: "40px",
        fontWeight: "bold",
        paddingTop: "80px",
        textAlign: "center",
        textTransform: "uppercase"
      }}>Gewinn einlösen</Typography>
      <Typography variant="body1" sx={{
        padding: "16px",
        textAlign: "center"
      }}>Bitte füllen Sie die Felder aus, damit wir den Gewinn zur Abholung bereitstellen können.</Typography>
      <Box sx={{
        display: "flex",
        flexDirection: "column"
      }}>
        <WinnerTextField
          label="Vorname"
          value={givenName}
          onChange={ev => setGivenName(ev.target.value)}
        />
        <WinnerTextField
          label="Nachname"
          value={surname}
          onChange={ev => setSurname(ev.target.value)}
        />
        <WinnerTextField
          label="E-Mail"
          value={email}
          onChange={ev => setEmail(ev.target.value)}
        />
        {customWinnerFields.map((field, i) => {
          return <WinnerTextField
            key={field.name}
            label={field.name}
            placeholder={field.placeholder}
            value={customFieldValues[i]}
            onChange={ev => setCustomFieldValues(customFieldValues.map((v, j) => i === j ? ev.target.value : v ))}
          />
        })}
        <Box>
          <Checkbox checked={participationRead} onChange={() => setParticipationRead(p => !p)} />
          Ich habe die <span style={{ color: optician?.design?.primaryColor, cursor: "pointer" }} onClick={() => setParticiaptionOpen(true)}>Teilnahmebedingungen</span> gelesen.
        </Box>
        <RedeemButton variant="contained" onClick={redeemPrice} sx={{
          color: optician?.design?.buttonColor
        }}>Jetzt Einlösen</RedeemButton>
      </Box>
      <TextDialog open={participationOpen} onClose={() => setParticiaptionOpen(false)}>
        <LegalText fetchText={() => api.getLegalTextsByOptician({ id: optician!!.id }).then(lt => lt.participation)} />
      </TextDialog>
    </Box>
  )
}