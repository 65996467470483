/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PriceImageType {
    Image = 'IMAGE',
    Lottie = 'LOTTIE'
}

export function PriceImageTypeFromJSON(json: any): PriceImageType {
    return PriceImageTypeFromJSONTyped(json, false);
}

export function PriceImageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceImageType {
    return json as PriceImageType;
}

export function PriceImageTypeToJSON(value?: PriceImageType | null): any {
    return value as any;
}

