/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PricePayload
 */
export interface PricePayload {
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    imageRef?: string;
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    title: string;
}

export function PricePayloadFromJSON(json: any): PricePayload {
    return PricePayloadFromJSONTyped(json, false);
}

export function PricePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'imageRef': !exists(json, 'imageRef') ? undefined : json['imageRef'],
        'title': json['title'],
    };
}

export function PricePayloadToJSON(value?: PricePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'imageRef': value.imageRef,
        'title': value.title,
    };
}


