/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginPayload
 */
export interface LoginPayload {
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    username: string;
}

export function LoginPayloadFromJSON(json: any): LoginPayload {
    return LoginPayloadFromJSONTyped(json, false);
}

export function LoginPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': json['password'],
        'username': json['username'],
    };
}

export function LoginPayloadToJSON(value?: LoginPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'username': value.username,
    };
}


