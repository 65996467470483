/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldType,
    FieldTypeFromJSON,
    FieldTypeFromJSONTyped,
    FieldTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomWinnerField
 */
export interface CustomWinnerField {
    /**
     * 
     * @type {FieldType}
     * @memberof CustomWinnerField
     */
    fieldType?: FieldType;
    /**
     * 
     * @type {string}
     * @memberof CustomWinnerField
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomWinnerField
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomWinnerField
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomWinnerField
     */
    required?: boolean;
}

export function CustomWinnerFieldFromJSON(json: any): CustomWinnerField {
    return CustomWinnerFieldFromJSONTyped(json, false);
}

export function CustomWinnerFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomWinnerField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldType': !exists(json, 'fieldType') ? undefined : FieldTypeFromJSON(json['fieldType']),
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'required': !exists(json, 'required') ? undefined : json['required'],
    };
}

export function CustomWinnerFieldToJSON(value?: CustomWinnerField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldType': FieldTypeToJSON(value.fieldType),
        'id': value.id,
        'name': value.name,
        'placeholder': value.placeholder,
        'required': value.required,
    };
}


