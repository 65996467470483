import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";

export const CenterFrame = (props: PropsWithChildren<{}>) => {
  return (
    <Box sx={{
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      margin: "auto",
      maxWidth: "90%",
      paddingBottom: "10vh",
      paddingTop: "5vh",
      width: "1024px"
    }}>
      {props.children}
    </Box>
  )
}

export const CenterPaper = (props: PropsWithChildren<{}>) => {
  return (
    <CenterFrame>
      <Paper sx={{
        alignItems: "center",
        boxShadow: "0px 24px 56px #00000029",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        padding: "16px"
      }}>
        {props.children}
      </Paper>
    </CenterFrame>
  )
}