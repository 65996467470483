/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    discountInvitation?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    emailText?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    id: string;
}

export function DiscountFromJSON(json: any): Discount {
    return DiscountFromJSONTyped(json, false);
}

export function DiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'discountInvitation': !exists(json, 'discountInvitation') ? undefined : json['discountInvitation'],
        'emailText': !exists(json, 'emailText') ? undefined : json['emailText'],
        'id': json['id'],
    };
}

export function DiscountToJSON(value?: Discount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'discountInvitation': value.discountInvitation,
        'emailText': value.emailText,
        'id': value.id,
    };
}


