import React, { PropsWithChildren, useContext, useMemo } from "react";
import { Configuration, DefaultApi } from "../gen/api";
import { apiBaseUrl } from "./Settings";

const ApiContext = React.createContext(new DefaultApi());

export const useApi = () => useContext(ApiContext);

export const ApiProvider = (props: PropsWithChildren<{}>) => {

  const api = useMemo(() => {
    return new DefaultApi(new Configuration({ basePath: apiBaseUrl }))
  }, []);

  return (
    <ApiContext.Provider value={api}>
      {props.children}
    </ApiContext.Provider>
  )
}

export const decodeError = (callback: (errorCode: string | null, httpCode: number | null) => void) => async (ex: any) => {
  if (typeof ex !== "object" || typeof ex.status !== "number") {
    return callback(null, null);
  }
  const code = ex.status;
  if (typeof ex.json !== "function") {
    return callback(null, code);
  }
  try {
    const response = await ex.json();
    if (typeof response.message !== "string") {
      return callback(null, code);
    }
    const message: string = response.message;
    if (message.indexOf(':') === -1) {
      return callback(null, code);
    }
    return callback(message.substring(0, message.indexOf(':')), code);
  } catch (ex) {
    return callback(null, code);
  }
}