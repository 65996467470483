import { Typography } from "@mui/material"
import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { CenterPaper } from "../common/CenterFrame"
import { Logo, TopImageBox } from "../common/Logo"
import { Headline } from "../common/Text"
import { Price, RaffleCode } from "../gen/api"
import { IconPriceWon } from "../icons/IconPriceWon"
import { useOptician } from "../optician_loader/OpticianTheme"
import { PriceView } from "./PriceView"
import { RedeemPriceForm } from "./RedeemPriceForm"
import Lottie from "react-lottie-player";

interface PriceResultProps {
  raffleCode: RaffleCode;
  price: Price;
}

const HighlightedCode = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold"
}))

export const PriceResult = (props: PriceResultProps) => {
  const optician = useOptician();

  return (
    <CenterPaper>
      <Logo />
      <TopImageBox>
        {optician?.raffle?.priceImageUrl &&
          (
            optician.raffle.priceImageType === "LOTTIE" ?
              <Lottie
                play
                path={optician?.raffle?.priceImageUrl}
                style={{ width: 150, height: 150 }}
              />
            :
              <img src={optician!!.raffle!!.priceImageUrl} alt="" style={{ width: 150, height: 150, objectFit: "contain" }} />
          )
        }
        {!optician?.raffle?.priceImageUrl && 
          <IconPriceWon />
        }
      </TopImageBox>
      <Headline>
        Herzlichen Glückwunsch!
      </Headline>
      <Typography variant="body1" sx={{
        fontSize: "16px",
        paddingBottom: "40px",
        paddingTop: "12px"
      }}>
        Mit dem Gutscheincode <HighlightedCode component="span">{props.raffleCode.codeNumber}</HighlightedCode> haben Sie folgendes gewonnen:
      </Typography>
      <PriceView price={props.price} />
      <RedeemPriceForm price={props.price} code={props.raffleCode.codeNumber!} />
    </CenterPaper>
  )
}