/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomWinnerField,
    CustomWinnerFieldFromJSON,
    CustomWinnerFieldToJSON,
    CustomWinnerFieldPayload,
    CustomWinnerFieldPayloadFromJSON,
    CustomWinnerFieldPayloadToJSON,
    Discount,
    DiscountFromJSON,
    DiscountToJSON,
    DiscountFriend,
    DiscountFriendFromJSON,
    DiscountFriendToJSON,
    DiscountPayload,
    DiscountPayloadFromJSON,
    DiscountPayloadToJSON,
    LegalTexts,
    LegalTextsFromJSON,
    LegalTextsToJSON,
    LegalTextsPayload,
    LegalTextsPayloadFromJSON,
    LegalTextsPayloadToJSON,
    LoginPayload,
    LoginPayloadFromJSON,
    LoginPayloadToJSON,
    OpticianDesign,
    OpticianDesignFromJSON,
    OpticianDesignToJSON,
    OpticianDesignPayload,
    OpticianDesignPayloadFromJSON,
    OpticianDesignPayloadToJSON,
    OpticianDetailed,
    OpticianDetailedFromJSON,
    OpticianDetailedToJSON,
    OpticianPaginated,
    OpticianPaginatedFromJSON,
    OpticianPaginatedToJSON,
    OpticianPayload,
    OpticianPayloadFromJSON,
    OpticianPayloadToJSON,
    Price,
    PriceFromJSON,
    PriceToJSON,
    PricePaginated,
    PricePaginatedFromJSON,
    PricePaginatedToJSON,
    PricePayload,
    PricePayloadFromJSON,
    PricePayloadToJSON,
    Raffle,
    RaffleFromJSON,
    RaffleToJSON,
    RaffleCode,
    RaffleCodeFromJSON,
    RaffleCodeToJSON,
    RaffleCodePaginated,
    RaffleCodePaginatedFromJSON,
    RaffleCodePaginatedToJSON,
    RafflePayload,
    RafflePayloadFromJSON,
    RafflePayloadToJSON,
    Session,
    SessionFromJSON,
    SessionToJSON,
    StringWrapper,
    StringWrapperFromJSON,
    StringWrapperToJSON,
    UploadInfo,
    UploadInfoFromJSON,
    UploadInfoToJSON,
    WinnerPayload,
    WinnerPayloadFromJSON,
    WinnerPayloadToJSON,
} from '../models';

export interface CreateOpticianRequest {
    opticianPayload: OpticianPayload;
}

export interface DeleteOpticianRequest {
    id: string;
}

export interface DeletePriceRequest {
    id: string;
}

export interface GetCustomWinnerFieldsByOpticianRequest {
    id: string;
}

export interface GetDiscountByOpticianRequest {
    id: string;
}

export interface GetLegalTextsByOpticianRequest {
    id: string;
}

export interface GetOpticianRequest {
    id: string;
}

export interface GetOpticianBySlugRequest {
    slug: string;
}

export interface GetOpticianDesignByOpticianRequest {
    id: string;
}

export interface GetOpticiansRequest {
    page?: number;
    size?: number;
    search?: string;
    orderColumn?: string;
    orderDir?: GetOpticiansOrderDirEnum;
}

export interface GetPriceRequest {
    id: string;
}

export interface GetPricesByOpticianRequest {
    id: string;
    page?: number;
    size?: number;
    search?: string;
    orderColumn?: string;
    orderDir?: GetPricesByOpticianOrderDirEnum;
}

export interface GetRaffleByOpticianRequest {
    id: string;
}

export interface GetRaffleCodeRequest {
    id: string;
}

export interface GetRaffleCodesByOpticianRequest {
    id: string;
    page?: number;
    size?: number;
    search?: string;
    orderColumn?: string;
    orderDir?: GetRaffleCodesByOpticianOrderDirEnum;
}

export interface GetRaffleCodesByOpticianAsCsvRequest {
    id: string;
}

export interface LoginRequest {
    loginPayload: LoginPayload;
}

export interface RedeemCodeRequest {
    opticianId: string;
    code: string;
    winnerPayload: WinnerPayload;
}

export interface RefreshSessionRequest {
    refreshToken: string;
}

export interface SendToFriendRequest {
    raffleCodeId: string;
    discountFriend: DiscountFriend;
}

export interface TryCodeRequest {
    opticianId: string;
    code: string;
}

export interface UpdateCustomWinnerFieldsByOpticianRequest {
    id: string;
    customWinnerFieldPayload: Array<CustomWinnerFieldPayload>;
}

export interface UpdateDiscountByOpticianRequest {
    id: string;
    discountPayload: DiscountPayload;
}

export interface UpdateLegalTextsByOpticianRequest {
    id: string;
    legalTextsPayload: LegalTextsPayload;
}

export interface UpdateOpticianRequest {
    id: string;
    opticianPayload: OpticianPayload;
}

export interface UpdateOpticianDesignByOpticianRequest {
    id: string;
    opticianDesignPayload: OpticianDesignPayload;
}

export interface UpdatePriceRequest {
    id: string;
    pricePayload: PricePayload;
}

export interface UpdatePricesByOpticianWithCsvRequest {
    id: string;
    body: Blob;
}

export interface UpdateRaffleByOpticianRequest {
    id: string;
    rafflePayload: RafflePayload;
}

export interface UpdateRaffleCodesByOpticianWithCsvRequest {
    id: string;
    body: Blob;
}

export interface UploadRequest {
    filename: string;
    contentType?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createOpticianRaw(requestParameters: CreateOpticianRequest): Promise<runtime.ApiResponse<OpticianDetailed>> {
        if (requestParameters.opticianPayload === null || requestParameters.opticianPayload === undefined) {
            throw new runtime.RequiredError('opticianPayload','Required parameter requestParameters.opticianPayload was null or undefined when calling createOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/opticians`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpticianPayloadToJSON(requestParameters.opticianPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianDetailedFromJSON(jsonValue));
    }

    /**
     */
    async createOptician(requestParameters: CreateOpticianRequest): Promise<OpticianDetailed> {
        const response = await this.createOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteOpticianRaw(requestParameters: DeleteOpticianRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/opticians/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOptician(requestParameters: DeleteOpticianRequest): Promise<void> {
        await this.deleteOpticianRaw(requestParameters);
    }

    /**
     */
    async deletePriceRaw(requestParameters: DeletePriceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prices/byId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePrice(requestParameters: DeletePriceRequest): Promise<void> {
        await this.deletePriceRaw(requestParameters);
    }

    /**
     */
    async getCustomWinnerFieldsByOpticianRaw(requestParameters: GetCustomWinnerFieldsByOpticianRequest): Promise<runtime.ApiResponse<Array<CustomWinnerField>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomWinnerFieldsByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customWinnerFields/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomWinnerFieldFromJSON));
    }

    /**
     */
    async getCustomWinnerFieldsByOptician(requestParameters: GetCustomWinnerFieldsByOpticianRequest): Promise<Array<CustomWinnerField>> {
        const response = await this.getCustomWinnerFieldsByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDiscountByOpticianRaw(requestParameters: GetDiscountByOpticianRequest): Promise<runtime.ApiResponse<Discount>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDiscountByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discounts/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountFromJSON(jsonValue));
    }

    /**
     */
    async getDiscountByOptician(requestParameters: GetDiscountByOpticianRequest): Promise<Discount> {
        const response = await this.getDiscountByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLegalTextsByOpticianRaw(requestParameters: GetLegalTextsByOpticianRequest): Promise<runtime.ApiResponse<LegalTexts>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLegalTextsByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/legal/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LegalTextsFromJSON(jsonValue));
    }

    /**
     */
    async getLegalTextsByOptician(requestParameters: GetLegalTextsByOpticianRequest): Promise<LegalTexts> {
        const response = await this.getLegalTextsByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOpticianRaw(requestParameters: GetOpticianRequest): Promise<runtime.ApiResponse<OpticianDetailed>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/opticians/byid/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianDetailedFromJSON(jsonValue));
    }

    /**
     */
    async getOptician(requestParameters: GetOpticianRequest): Promise<OpticianDetailed> {
        const response = await this.getOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOpticianBySlugRaw(requestParameters: GetOpticianBySlugRequest): Promise<runtime.ApiResponse<OpticianDetailed>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getOpticianBySlug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/opticians/bySlug/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianDetailedFromJSON(jsonValue));
    }

    /**
     */
    async getOpticianBySlug(requestParameters: GetOpticianBySlugRequest): Promise<OpticianDetailed> {
        const response = await this.getOpticianBySlugRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOpticianDesignByOpticianRaw(requestParameters: GetOpticianDesignByOpticianRequest): Promise<runtime.ApiResponse<OpticianDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOpticianDesignByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/opticianDesigns/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianDesignFromJSON(jsonValue));
    }

    /**
     */
    async getOpticianDesignByOptician(requestParameters: GetOpticianDesignByOpticianRequest): Promise<OpticianDesign> {
        const response = await this.getOpticianDesignByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOpticiansRaw(requestParameters: GetOpticiansRequest): Promise<runtime.ApiResponse<OpticianPaginated>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderColumn !== undefined) {
            queryParameters['orderColumn'] = requestParameters.orderColumn;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/opticians`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianPaginatedFromJSON(jsonValue));
    }

    /**
     */
    async getOpticians(requestParameters: GetOpticiansRequest): Promise<OpticianPaginated> {
        const response = await this.getOpticiansRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPriceRaw(requestParameters: GetPriceRequest): Promise<runtime.ApiResponse<Price>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prices/byId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceFromJSON(jsonValue));
    }

    /**
     */
    async getPrice(requestParameters: GetPriceRequest): Promise<Price> {
        const response = await this.getPriceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPricesByOpticianRaw(requestParameters: GetPricesByOpticianRequest): Promise<runtime.ApiResponse<PricePaginated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPricesByOptician.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderColumn !== undefined) {
            queryParameters['orderColumn'] = requestParameters.orderColumn;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prices/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PricePaginatedFromJSON(jsonValue));
    }

    /**
     */
    async getPricesByOptician(requestParameters: GetPricesByOpticianRequest): Promise<PricePaginated> {
        const response = await this.getPricesByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRaffleByOpticianRaw(requestParameters: GetRaffleByOpticianRequest): Promise<runtime.ApiResponse<Raffle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRaffleByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/raffles/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RaffleFromJSON(jsonValue));
    }

    /**
     */
    async getRaffleByOptician(requestParameters: GetRaffleByOpticianRequest): Promise<Raffle> {
        const response = await this.getRaffleByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRaffleCodeRaw(requestParameters: GetRaffleCodeRequest): Promise<runtime.ApiResponse<RaffleCode>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRaffleCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/raffleCodes/byId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RaffleCodeFromJSON(jsonValue));
    }

    /**
     */
    async getRaffleCode(requestParameters: GetRaffleCodeRequest): Promise<RaffleCode> {
        const response = await this.getRaffleCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRaffleCodesByOpticianRaw(requestParameters: GetRaffleCodesByOpticianRequest): Promise<runtime.ApiResponse<RaffleCodePaginated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRaffleCodesByOptician.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderColumn !== undefined) {
            queryParameters['orderColumn'] = requestParameters.orderColumn;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/raffleCodes/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RaffleCodePaginatedFromJSON(jsonValue));
    }

    /**
     */
    async getRaffleCodesByOptician(requestParameters: GetRaffleCodesByOpticianRequest): Promise<RaffleCodePaginated> {
        const response = await this.getRaffleCodesByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRaffleCodesByOpticianAsCsvRaw(requestParameters: GetRaffleCodesByOpticianAsCsvRequest): Promise<runtime.ApiResponse<StringWrapper>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRaffleCodesByOpticianAsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/raffleCodes/byOptician/{id}/csv`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StringWrapperFromJSON(jsonValue));
    }

    /**
     */
    async getRaffleCodesByOpticianAsCsv(requestParameters: GetRaffleCodesByOpticianAsCsvRequest): Promise<StringWrapper> {
        const response = await this.getRaffleCodesByOpticianAsCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async heartbeatRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/heartbeats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async heartbeat(): Promise<void> {
        await this.heartbeatRaw();
    }

    /**
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters.loginPayload === null || requestParameters.loginPayload === undefined) {
            throw new runtime.RequiredError('loginPayload','Required parameter requestParameters.loginPayload was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginPayloadToJSON(requestParameters.loginPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginRequest): Promise<Session> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async redeemCodeRaw(requestParameters: RedeemCodeRequest): Promise<runtime.ApiResponse<RaffleCode>> {
        if (requestParameters.opticianId === null || requestParameters.opticianId === undefined) {
            throw new runtime.RequiredError('opticianId','Required parameter requestParameters.opticianId was null or undefined when calling redeemCode.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling redeemCode.');
        }

        if (requestParameters.winnerPayload === null || requestParameters.winnerPayload === undefined) {
            throw new runtime.RequiredError('winnerPayload','Required parameter requestParameters.winnerPayload was null or undefined when calling redeemCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/raffleCodes/redeem/{opticianId}/{code}`.replace(`{${"opticianId"}}`, encodeURIComponent(String(requestParameters.opticianId))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WinnerPayloadToJSON(requestParameters.winnerPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RaffleCodeFromJSON(jsonValue));
    }

    /**
     */
    async redeemCode(requestParameters: RedeemCodeRequest): Promise<RaffleCode> {
        const response = await this.redeemCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async refreshSessionRaw(requestParameters: RefreshSessionRequest): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters.refreshToken === null || requestParameters.refreshToken === undefined) {
            throw new runtime.RequiredError('refreshToken','Required parameter requestParameters.refreshToken was null or undefined when calling refreshSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.refreshToken !== undefined) {
            queryParameters['refreshToken'] = requestParameters.refreshToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async refreshSession(requestParameters: RefreshSessionRequest): Promise<Session> {
        const response = await this.refreshSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendToFriendRaw(requestParameters: SendToFriendRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.raffleCodeId === null || requestParameters.raffleCodeId === undefined) {
            throw new runtime.RequiredError('raffleCodeId','Required parameter requestParameters.raffleCodeId was null or undefined when calling sendToFriend.');
        }

        if (requestParameters.discountFriend === null || requestParameters.discountFriend === undefined) {
            throw new runtime.RequiredError('discountFriend','Required parameter requestParameters.discountFriend was null or undefined when calling sendToFriend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discounts/sendToFriend/{raffleCodeId}`.replace(`{${"raffleCodeId"}}`, encodeURIComponent(String(requestParameters.raffleCodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountFriendToJSON(requestParameters.discountFriend),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendToFriend(requestParameters: SendToFriendRequest): Promise<void> {
        await this.sendToFriendRaw(requestParameters);
    }

    /**
     */
    async tryCodeRaw(requestParameters: TryCodeRequest): Promise<runtime.ApiResponse<RaffleCode>> {
        if (requestParameters.opticianId === null || requestParameters.opticianId === undefined) {
            throw new runtime.RequiredError('opticianId','Required parameter requestParameters.opticianId was null or undefined when calling tryCode.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling tryCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/raffleCodes/try/{opticianId}/{code}`.replace(`{${"opticianId"}}`, encodeURIComponent(String(requestParameters.opticianId))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RaffleCodeFromJSON(jsonValue));
    }

    /**
     */
    async tryCode(requestParameters: TryCodeRequest): Promise<RaffleCode> {
        const response = await this.tryCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCustomWinnerFieldsByOpticianRaw(requestParameters: UpdateCustomWinnerFieldsByOpticianRequest): Promise<runtime.ApiResponse<Array<CustomWinnerField>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomWinnerFieldsByOptician.');
        }

        if (requestParameters.customWinnerFieldPayload === null || requestParameters.customWinnerFieldPayload === undefined) {
            throw new runtime.RequiredError('customWinnerFieldPayload','Required parameter requestParameters.customWinnerFieldPayload was null or undefined when calling updateCustomWinnerFieldsByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customWinnerFields/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customWinnerFieldPayload.map(CustomWinnerFieldPayloadToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomWinnerFieldFromJSON));
    }

    /**
     */
    async updateCustomWinnerFieldsByOptician(requestParameters: UpdateCustomWinnerFieldsByOpticianRequest): Promise<Array<CustomWinnerField>> {
        const response = await this.updateCustomWinnerFieldsByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateDiscountByOpticianRaw(requestParameters: UpdateDiscountByOpticianRequest): Promise<runtime.ApiResponse<Discount>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDiscountByOptician.');
        }

        if (requestParameters.discountPayload === null || requestParameters.discountPayload === undefined) {
            throw new runtime.RequiredError('discountPayload','Required parameter requestParameters.discountPayload was null or undefined when calling updateDiscountByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discounts/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountPayloadToJSON(requestParameters.discountPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountFromJSON(jsonValue));
    }

    /**
     */
    async updateDiscountByOptician(requestParameters: UpdateDiscountByOpticianRequest): Promise<Discount> {
        const response = await this.updateDiscountByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateLegalTextsByOpticianRaw(requestParameters: UpdateLegalTextsByOpticianRequest): Promise<runtime.ApiResponse<LegalTexts>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLegalTextsByOptician.');
        }

        if (requestParameters.legalTextsPayload === null || requestParameters.legalTextsPayload === undefined) {
            throw new runtime.RequiredError('legalTextsPayload','Required parameter requestParameters.legalTextsPayload was null or undefined when calling updateLegalTextsByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/legal/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LegalTextsPayloadToJSON(requestParameters.legalTextsPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LegalTextsFromJSON(jsonValue));
    }

    /**
     */
    async updateLegalTextsByOptician(requestParameters: UpdateLegalTextsByOpticianRequest): Promise<LegalTexts> {
        const response = await this.updateLegalTextsByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateOpticianRaw(requestParameters: UpdateOpticianRequest): Promise<runtime.ApiResponse<OpticianDetailed>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOptician.');
        }

        if (requestParameters.opticianPayload === null || requestParameters.opticianPayload === undefined) {
            throw new runtime.RequiredError('opticianPayload','Required parameter requestParameters.opticianPayload was null or undefined when calling updateOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/opticians/byId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OpticianPayloadToJSON(requestParameters.opticianPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianDetailedFromJSON(jsonValue));
    }

    /**
     */
    async updateOptician(requestParameters: UpdateOpticianRequest): Promise<OpticianDetailed> {
        const response = await this.updateOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateOpticianDesignByOpticianRaw(requestParameters: UpdateOpticianDesignByOpticianRequest): Promise<runtime.ApiResponse<OpticianDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOpticianDesignByOptician.');
        }

        if (requestParameters.opticianDesignPayload === null || requestParameters.opticianDesignPayload === undefined) {
            throw new runtime.RequiredError('opticianDesignPayload','Required parameter requestParameters.opticianDesignPayload was null or undefined when calling updateOpticianDesignByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/opticianDesigns/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OpticianDesignPayloadToJSON(requestParameters.opticianDesignPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OpticianDesignFromJSON(jsonValue));
    }

    /**
     */
    async updateOpticianDesignByOptician(requestParameters: UpdateOpticianDesignByOpticianRequest): Promise<OpticianDesign> {
        const response = await this.updateOpticianDesignByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePriceRaw(requestParameters: UpdatePriceRequest): Promise<runtime.ApiResponse<Price>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePrice.');
        }

        if (requestParameters.pricePayload === null || requestParameters.pricePayload === undefined) {
            throw new runtime.RequiredError('pricePayload','Required parameter requestParameters.pricePayload was null or undefined when calling updatePrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prices/byId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PricePayloadToJSON(requestParameters.pricePayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceFromJSON(jsonValue));
    }

    /**
     */
    async updatePrice(requestParameters: UpdatePriceRequest): Promise<Price> {
        const response = await this.updatePriceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePricesByOpticianWithCsvRaw(requestParameters: UpdatePricesByOpticianWithCsvRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePricesByOpticianWithCsv.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updatePricesByOpticianWithCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/csv';

        const response = await this.request({
            path: `/prices/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePricesByOpticianWithCsv(requestParameters: UpdatePricesByOpticianWithCsvRequest): Promise<void> {
        await this.updatePricesByOpticianWithCsvRaw(requestParameters);
    }

    /**
     */
    async updateRaffleByOpticianRaw(requestParameters: UpdateRaffleByOpticianRequest): Promise<runtime.ApiResponse<Raffle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRaffleByOptician.');
        }

        if (requestParameters.rafflePayload === null || requestParameters.rafflePayload === undefined) {
            throw new runtime.RequiredError('rafflePayload','Required parameter requestParameters.rafflePayload was null or undefined when calling updateRaffleByOptician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/raffles/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RafflePayloadToJSON(requestParameters.rafflePayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RaffleFromJSON(jsonValue));
    }

    /**
     */
    async updateRaffleByOptician(requestParameters: UpdateRaffleByOpticianRequest): Promise<Raffle> {
        const response = await this.updateRaffleByOpticianRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateRaffleCodesByOpticianWithCsvRaw(requestParameters: UpdateRaffleCodesByOpticianWithCsvRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRaffleCodesByOpticianWithCsv.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateRaffleCodesByOpticianWithCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/csv';

        const response = await this.request({
            path: `/raffleCodes/byOptician/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateRaffleCodesByOpticianWithCsv(requestParameters: UpdateRaffleCodesByOpticianWithCsvRequest): Promise<void> {
        await this.updateRaffleCodesByOpticianWithCsvRaw(requestParameters);
    }

    /**
     */
    async uploadRaw(requestParameters: UploadRequest): Promise<runtime.ApiResponse<UploadInfo>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling upload.');
        }

        const queryParameters: any = {};

        if (requestParameters.contentType !== undefined) {
            queryParameters['contentType'] = requestParameters.contentType;
        }

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/upload`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadInfoFromJSON(jsonValue));
    }

    /**
     */
    async upload(requestParameters: UploadRequest): Promise<UploadInfo> {
        const response = await this.uploadRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetOpticiansOrderDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPricesByOpticianOrderDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum GetRaffleCodesByOpticianOrderDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
