import { Box } from "@mui/system";
import { useOptician } from "../optician_loader/OpticianTheme"
import { CenterPaper } from "../common/CenterFrame";
import { CodeInput } from "./CodeInput";
import { Logo } from "../common/Logo";
import { Headline } from "../common/Text";
import { RichText } from "../common/RichText";


export const EnterCode = () => {
  const optician = useOptician();

  return (
    <CenterPaper>
      <Logo />
      <Headline>{optician?.raffle?.name}</Headline>
      <RichText text={optician?.raffle?.greeting ?? ""} centered={true} />
      <Box sx={{
        display: "flex",
        paddingBottom: "80px"
      }}>
        <CodeInput />
      </Box>
    </CenterPaper>
  )
}