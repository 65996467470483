/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Optician,
    OpticianFromJSON,
    OpticianFromJSONTyped,
    OpticianToJSON,
    OpticianPaginatedAllOf,
    OpticianPaginatedAllOfFromJSON,
    OpticianPaginatedAllOfFromJSONTyped,
    OpticianPaginatedAllOfToJSON,
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpticianPaginated
 */
export interface OpticianPaginated {
    /**
     * 
     * @type {number}
     * @memberof OpticianPaginated
     */
    recordsTotal?: number;
    /**
     * 
     * @type {Array<Optician>}
     * @memberof OpticianPaginated
     */
    data: Array<Optician>;
}

export function OpticianPaginatedFromJSON(json: any): OpticianPaginated {
    return OpticianPaginatedFromJSONTyped(json, false);
}

export function OpticianPaginatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpticianPaginated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordsTotal': !exists(json, 'recordsTotal') ? undefined : json['recordsTotal'],
        'data': ((json['data'] as Array<any>).map(OpticianFromJSON)),
    };
}

export function OpticianPaginatedToJSON(value?: OpticianPaginated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordsTotal': value.recordsTotal,
        'data': ((value.data as Array<any>).map(OpticianToJSON)),
    };
}


