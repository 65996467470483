import { Button, TextField } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useOptician } from "../optician_loader/OpticianTheme";
import { useSnackbar } from "notistack";


export const CodeInput = () => {

  const optician = useOptician();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [code, setCode] = useState<string>("");

  const updateCode = useCallback((code: string) => {
    if (code.length <= 10) {
      setCode(code);
    }
  }, [setCode])

  const tryCode = useCallback(() => {
    if (!code) {
      enqueueSnackbar("Bitte geben Sie einen Code an", { variant: "error" });
      return;
    }
    const newPath = `/${optician!.slug}/code/${code}`;
    if (newPath === location.pathname) {
      history.go(0);
    } else {
      history.push(newPath);
    }
  }, [enqueueSnackbar, history, location, code, optician]);

  return (
    <>
    <TextField
      placeholder="Code eingeben"
      value={code}
      onChange={ev => updateCode(ev.target.value)}
      variant="filled"
      InputProps={{
        sx: {
          borderRadius: "4px 0px 0px 4px",
          fontSize: "24px",
          fontWeight: "bold",
          input: {
            paddingBottom: "12px",
            paddingTop: "20px",
            textAlign: "center"
          },
          "input::placeholder": {
            textTransform: "uppercase"
          }
        },
        disableUnderline: true
      }}
    />
    <Button
      color="primary"
      variant="contained"
      onClick={tryCode}
      sx={{
        borderRadius: "0px 4px 4px 0px"
      }}
    >
      <ArrowForwardIcon sx={{
        color: optician?.design?.buttonColor
      }} />
    </Button>
    </>
  )
}