import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { createContext, PropsWithChildren, useContext, useMemo } from "react"
import { OpticianDetailed } from "../gen/api"

const OpticianContext = createContext<OpticianDetailed | null>(null);

export const useOptician = () => useContext(OpticianContext);

interface OpticianThemeProps {
  optician: OpticianDetailed | null;
}

export const OpticianTheme = (props: PropsWithChildren<OpticianThemeProps>) => {

  const { optician } = props;

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        primary: {
          main: optician?.design?.primaryColor ?? "#C41528"
        },
        text: {
          primary: optician?.design?.fontColor ?? "#656569"
        }
      }
    });
  }, [optician]);

  return (
    <ThemeProvider theme={theme}>
      <OpticianContext.Provider value={optician}>
        {props.children}
      </OpticianContext.Provider>
    </ThemeProvider>
  )
}