import { Box, styled } from "@mui/system"
import { useCallback, useState } from "react";
import { useApi } from "../common/ApiProvider";
import { useOptician } from "../optician_loader/OpticianTheme";
import { LegalText } from "./LegalText";
import { TextDialog } from "./TextDialog";
import {LegalTexts} from "../gen/api";
import {useSnackbar} from "notistack";

const BottomBarLink = styled("a")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "14px",
  padding: "18px"
}));

export const BottomBar = () => {
  const api = useApi();
  const optician = useOptician();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogShowing, setDialogShowing] = useState<"data" | "participating" | "imprint" | null>(null);

  const onClose = useCallback(() => {
    setDialogShowing(null);
  }, [setDialogShowing]);

  const [legalTexts, setLegalTexts] = useState<LegalTexts>();

  const loadLegalTexts = async () => {
    if (legalTexts) {
      return legalTexts;
    }
    try {
      const lt = await api.getLegalTextsByOptician({ id: optician?.id!! })
      setLegalTexts(lt);
      return lt;
    } catch (ex) {
      console.error("Failed to load legal texts", ex);
      enqueueSnackbar("Rechtliche Texte konnten nicht geladen werden", { variant: "error" })
    }
  }

  return (
    <Box sx={{
      width: "100%"
    }}>
      <Box sx={{
        alignItems: "center",
        backgroundColor: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        opacity: 0.9,
        flexWrap: "wrap"
      }}>
        <BottomBarLink onClick={() => setDialogShowing("data")}>Datenschutzbestimmungen</BottomBarLink>
        <BottomBarLink onClick={() => setDialogShowing("participating")}>Teilnahmebedingungen</BottomBarLink>
        <BottomBarLink onClick={() => setDialogShowing("imprint")}>Impressum</BottomBarLink>
        <TextDialog open={dialogShowing === "data"} onClose={onClose}>
          <LegalText fetchText={() => loadLegalTexts().then(lt => lt?.dataPrivacy)} />
        </TextDialog>
        <TextDialog open={dialogShowing === "participating"} onClose={onClose}>
          <LegalText fetchText={() => loadLegalTexts().then(lt => lt?.participation)} />
        </TextDialog>
        <TextDialog open={dialogShowing === "imprint"} onClose={onClose}>
          <LegalText fetchText={() => loadLegalTexts().then(lt => lt?.imprint)} />
        </TextDialog>
      </Box>
    </Box>
  )
}