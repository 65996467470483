import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Route, Switch, useParams } from "react-router";
import { useApi } from "../common/ApiProvider"
import { CodeResult } from "../code_result/CodeResult";
import { OfferDiscount } from "../discount/OfferDiscount";
import { EnterCode } from "../enter_code/EnterCode";
import { OpticianDetailed } from "../gen/api";
import { LoadingSpinner } from "../common/LoadingSpinner";

interface OpticianLoaderProps {
  optician: OpticianDetailed | null;
  setOptician: (optician: OpticianDetailed | null) => void;
}

const preloadImage = (src: string) => {
  return new Promise(function(resolve, reject) {
      const image = new Image();
      image.addEventListener('load', ev => {
        image.remove();
        resolve(ev);
      });
      image.addEventListener('error', ev => {
        image.remove();
        reject(ev)
      });
      image.src = src;
  });
}

const createFontCss = (name: string, selector: string, fontUrl: string): HTMLStyleElement | null => {
  const styleEl = document.createElement("style");
  if (fontUrl) {
    styleEl.innerHTML = `@font-face { font-family: ${name}; src: url('${fontUrl}') } ${selector} { font-family: ${name} !important }`;
  }
  document.head.appendChild(styleEl);
  return styleEl;
}

export const OpticianLoader = (props: OpticianLoaderProps) => {

  const api = useApi();

  const { optician, setOptician } = props;

  const { opticianSlug } = useParams<{opticianSlug: string}>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    api.getOpticianBySlug({ slug: opticianSlug }).then(async optician => {
      setOptician(optician);
      const backgroundImageUrl = optician.design?.backgroundImageUrl;
      if (backgroundImageUrl) {
        try {
          await preloadImage(backgroundImageUrl);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }).catch(err => {
      console.error("Failed to load optician data", err);
      setLoading(false);
      setError(true);
    })
  }, [api, opticianSlug, setOptician]);

  useEffect(() => {
    if (!optician) {
      return;
    }
    const elements: (HTMLElement | null)[] = [];
    const defaultFontUrl = "https://iga-raffle-media.s3.eu-central-1.amazonaws.com/static/OpenSans.woff2";
    elements.push(createFontCss("BodyFont", "body, button, p, input, label", optician?.design?.bodyFontUrl ?? defaultFontUrl));
    elements.push(createFontCss("HeaderFont", "h1, h2, h3, h4, h5, h6", optician?.design?.headerFontUrl ?? defaultFontUrl));
    return () => {
      for (const element of elements) {
        if (element) {
          document.head.removeChild(element);
        }
      }
    };
  }, [optician]);

  useEffect(() => {
    const raffleName = optician?.raffle?.name;
    if (!raffleName) {
      return;
    }
    document.title = raffleName;
  }, [optician])

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        primary: {
          main: optician?.design?.primaryColor ?? "#C41528"
        },
        text: {
          primary: optician?.design?.fontColor ?? "#656569"
        }
      }
    });
  }, [optician]);

  if (loading || error) {
    return (
      <LoadingSpinner error={error} text="Gewinnspiel wird geladen" />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/:opticianId" exact={true}>
          <EnterCode />
        </Route>
        <Route path="/:opticianId/code/:code" exact={true}>
          <CodeResult />
        </Route>
        <Route path="/:opticianId/success/:codeId" exact={true}>
          <OfferDiscount />
        </Route>
      </Switch>
    </ThemeProvider>
  )
}
