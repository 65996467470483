/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceImageType,
    PriceImageTypeFromJSON,
    PriceImageTypeFromJSONTyped,
    PriceImageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RafflePayload
 */
export interface RafflePayload {
    /**
     * 
     * @type {string}
     * @memberof RafflePayload
     */
    greeting: string;
    /**
     * 
     * @type {string}
     * @memberof RafflePayload
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RafflePayload
     */
    preparePriceMessage: string;
    /**
     * 
     * @type {string}
     * @memberof RafflePayload
     */
    priceImageRef?: string;
    /**
     * 
     * @type {PriceImageType}
     * @memberof RafflePayload
     */
    priceImageType?: PriceImageType;
}

export function RafflePayloadFromJSON(json: any): RafflePayload {
    return RafflePayloadFromJSONTyped(json, false);
}

export function RafflePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): RafflePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'greeting': json['greeting'],
        'name': json['name'],
        'preparePriceMessage': json['preparePriceMessage'],
        'priceImageRef': !exists(json, 'priceImageRef') ? undefined : json['priceImageRef'],
        'priceImageType': !exists(json, 'priceImageType') ? undefined : PriceImageTypeFromJSON(json['priceImageType']),
    };
}

export function RafflePayloadToJSON(value?: RafflePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'greeting': value.greeting,
        'name': value.name,
        'preparePriceMessage': value.preparePriceMessage,
        'priceImageRef': value.priceImageRef,
        'priceImageType': PriceImageTypeToJSON(value.priceImageType),
    };
}


