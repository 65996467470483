import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CenterPaper } from "../common/CenterFrame"
import { TopImageBox } from "../common/Logo";
import { Headline } from "../common/Text";
import { CodeInput } from "../enter_code/CodeInput";
import { IconPriceError } from "../icons/IconPriceError";
import { IconPriceRivet } from "../icons/IconPriceRivet";


interface NoPriceResultProps {
  error: boolean;
}

export const NoPriceResult = (props: NoPriceResultProps) => {
  return (
    <CenterPaper>
      <TopImageBox>
        {props.error && 
          <IconPriceError />
        }
        {!props.error &&
          <IconPriceRivet />
        }
      </TopImageBox>
      <Headline>
        {props.error &&
          "Ups!"
        }
        {!props.error &&
          "Leider kein Gewinn"
        }
      </Headline>
      <Typography variant="body1" sx={{
        fontSize: "16px",
        paddingBottom: "40px",
        paddingTop: "12px",
        textAlign: "center"
      }}>
        {props.error &&
          "Dieser Code ist uns leider nicht bekannt. Bitte überprüfen Sie Ihre Eingabe."
        }
        {!props.error &&
          "Beim nächsten mal klappt es bestimmt. Wir drücken die Daumen!"
        }
      </Typography>
      <Box sx={{
        display: "flex",
        paddingBottom: "80px"
      }}>
        <CodeInput />
      </Box>
    </CenterPaper>
  )
}