/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountPayload
 */
export interface DiscountPayload {
    /**
     * 
     * @type {boolean}
     * @memberof DiscountPayload
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiscountPayload
     */
    discountInvitation: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountPayload
     */
    emailText: string;
}

export function DiscountPayloadFromJSON(json: any): DiscountPayload {
    return DiscountPayloadFromJSONTyped(json, false);
}

export function DiscountPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': json['active'],
        'discountInvitation': json['discountInvitation'],
        'emailText': json['emailText'],
    };
}

export function DiscountPayloadToJSON(value?: DiscountPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'discountInvitation': value.discountInvitation,
        'emailText': value.emailText,
    };
}


