import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApi } from "../common/ApiProvider";
import { RaffleCode } from "../gen/api";
import { useOptician } from "../optician_loader/OpticianTheme"
import { NoPriceResult } from "./NoPriceResult";
import { PriceResult } from "./PriceResult";
import {Waiting} from "./Waiting";


export const CodeResult = () => {
  const optician = useOptician();

  const api = useApi();

  const { code } = useParams<{code: string}>();

  const [raffleCode, setRaffleCode] = useState<RaffleCode>();
  const [error, setError] = useState<boolean>(false);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setWaiting(true);
    const timeoutId = setTimeout(() => {
      setWaiting(false)
    }, Math.floor((optician?.design?.waitingTime ?? 5) * 1000));
    return () => clearTimeout(timeoutId)
  }, [code, optician?.design?.waitingTime])

  useEffect(() => {
    setError(false);
    setRaffleCode(undefined);
    api.tryCode({ code, opticianId: optician!.id }).then(raffleCode => {
      setRaffleCode(raffleCode);
    }).catch((ex: Response) => {
      console.error(ex);
      setError(true);
    })
  }, [api, code, optician, setRaffleCode, setError]);

  if (error || raffleCode?.usedBy) {
    return (
      <NoPriceResult error={true} />
    )
  }

  if (waiting || (!raffleCode && !error)) {
    return (
      <Waiting optician={optician ?? undefined} />
    )
  }

  if (!raffleCode?.price) {
    return (
      <NoPriceResult error={false} />
    )
  }

  return (
    <PriceResult raffleCode={raffleCode} price={raffleCode.price} />
  )
}
