/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceImageType,
    PriceImageTypeFromJSON,
    PriceImageTypeFromJSONTyped,
    PriceImageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Raffle
 */
export interface Raffle {
    /**
     * 
     * @type {string}
     * @memberof Raffle
     */
    greeting?: string;
    /**
     * 
     * @type {string}
     * @memberof Raffle
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Raffle
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Raffle
     */
    preparePriceMessage?: string;
    /**
     * 
     * @type {PriceImageType}
     * @memberof Raffle
     */
    priceImageType?: PriceImageType;
    /**
     * 
     * @type {string}
     * @memberof Raffle
     */
    priceImageUrl?: string;
}

export function RaffleFromJSON(json: any): Raffle {
    return RaffleFromJSONTyped(json, false);
}

export function RaffleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Raffle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'greeting': !exists(json, 'greeting') ? undefined : json['greeting'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'preparePriceMessage': !exists(json, 'preparePriceMessage') ? undefined : json['preparePriceMessage'],
        'priceImageType': !exists(json, 'priceImageType') ? undefined : PriceImageTypeFromJSON(json['priceImageType']),
        'priceImageUrl': !exists(json, 'priceImageUrl') ? undefined : json['priceImageUrl'],
    };
}

export function RaffleToJSON(value?: Raffle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'greeting': value.greeting,
        'id': value.id,
        'name': value.name,
        'preparePriceMessage': value.preparePriceMessage,
        'priceImageType': PriceImageTypeToJSON(value.priceImageType),
        'priceImageUrl': value.priceImageUrl,
    };
}


