/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ImageRepeat {
    Repeat = 'REPEAT',
    None = 'NONE'
}

export function ImageRepeatFromJSON(json: any): ImageRepeat {
    return ImageRepeatFromJSONTyped(json, false);
}

export function ImageRepeatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageRepeat {
    return json as ImageRepeat;
}

export function ImageRepeatToJSON(value?: ImageRepeat | null): any {
    return value as any;
}

