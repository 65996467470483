import { Button, Checkbox, TextField } from "@mui/material"
import { Box, styled } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { LegalText } from "../bottom_bar/LegalText";
import { TextDialog } from "../bottom_bar/TextDialog";
import { useApi } from "../common/ApiProvider";
import { RichText } from "../common/RichText";
import { Discount } from "../gen/api";
import { useOptician } from "../optician_loader/OpticianTheme";

const DiscountTextField = styled(TextField)(() => ({
  maxWidth: "676px",
  margin: "8px",
  width: "calc(100% - 10px)",
  "& input": {
    color: "white"
  },
  "& label": {
    color: "#FFFFFF99"
  },
  "& fieldset": {
    borderColor: "#FFFFFF33"
  }
}));

const SendButton = styled(Button)(() => ({
  borderRadius: 0,
  fontWeight: "bold",
  height: "56px",
  margin: "auto",
  marginTop: "8px",
  width: "327px"
}));

interface DiscountInputProps {
  discount: Discount;
  sendDiscount: (givenName: string, surname: string, email: string) => void;
}

export const DiscountInput = (props: DiscountInputProps) => {

  const { sendDiscount } = props;

  const api = useApi();
  const optician = useOptician();
  const { enqueueSnackbar } = useSnackbar();
  const [givenName, setGivenName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [participationRead, setParticipationRead] = useState(false);
  const [participationOpen, setParticiaptionOpen] = useState(false);

  const onSendDiscountInput = useCallback(() => {
    if (!participationRead) {
      enqueueSnackbar("Bitte lesen Sie die Teilnahmebedingungen", { variant: "error" });
      return;
    }
    if (!givenName) {
      enqueueSnackbar("Bitte geben Sie den Vornamen an", { variant: "error" });
      return;
    }
    if (!surname) {
      enqueueSnackbar("Bitte geben Sie den Nachnamen an", { variant: "error" });
      return;
    }
    if (!email) {
      enqueueSnackbar("Bitte geben Sie die E-Mail Adresse an", { variant: "error" });
      return;
    }
    sendDiscount(givenName, surname, email);
  }, [enqueueSnackbar, givenName, surname, email, sendDiscount, participationRead]);

  return (
    <>
      <RichText text={optician?.discount?.discountInvitation ?? ""} centered={true} whiteText={true} />
      <DiscountTextField
        label="Vorname"
        value={givenName}
        onChange={ev => setGivenName(ev.target.value)}
      />
      <DiscountTextField
        label="Nachname"
        value={surname}
        onChange={ev => setSurname(ev.target.value)}
      />
      <DiscountTextField
        label="E-Mail"
        value={email}
        onChange={ev => setEmail(ev.target.value)}
      />
      <Box sx={{
        color: "white",
        maxWidth: "676px",
        textAlign: "left",
        width: "calc(100% - 10px)"
      }}>
        <Checkbox checked={participationRead} onChange={() => setParticipationRead(p => !p)} sx={{
          "& svg": {
            color: "white"
          }
        }} />
        Ich habe die <span style={{ color: optician?.design?.primaryColor, cursor: "pointer" }} onClick={() => setParticiaptionOpen(true)}>Teilnahmebedingungen</span> gelesen.
      </Box>
      <SendButton variant="contained" onClick={onSendDiscountInput} sx={{
        color: optician?.design?.buttonColor
      }}>Abschicken</SendButton>
      <TextDialog open={participationOpen} onClose={() => setParticiaptionOpen(false)}>
        <LegalText fetchText={() => api.getLegalTextsByOptician({ id: optician!!.id }).then(lt => lt.participation)} />
      </TextDialog>
    </>
  )
}