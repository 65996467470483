import { useOptician } from "../optician_loader/OpticianTheme";


export const IconPriceRivet = () => {
  const optician = useOptician();
  const primaryColor = optician!.design!.primaryColor;
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 93" cx="16.85" cy="129.61" r="30.57" gradientTransform="translate(45.82 87.82) scale(1.32 0.29)" gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stop-opacity="0.4"/>
          <stop offset="0.17" stop-opacity="0.31"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="b" data-name="Unbenannter Verlauf 56" cx="26.42" cy="51.16" r="50.33" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </radialGradient>
      </defs>
      <ellipse cx="68" cy="125.86" rx="42.38" ry="8.98" style={{fill: "url(#a)"}}/>
      <g>
        <path d="M102.22,72.07V59.8a6,6,0,1,0-12,0V53.56a9.42,9.42,0,0,0-18.83,0v2.69L64.08,42.8,69,26.28a8.53,8.53,0,1,0-16.37-4.83l-.09.33-3.81-7.24a7.93,7.93,0,0,0-10.51-3.42h0A7.93,7.93,0,0,0,34.73,22l11.5,21.32L36.94,75.08c-4,13.61,1.22,43.15,32.64,43.15S100,94.15,100.75,89.13a40,40,0,0,0-.37-11l.29-.47A10.9,10.9,0,0,0,102.22,72.07Z" style={{ fill: "#fff"}} />
        <path d="M102.22,72.07V59.8a6,6,0,1,0-12,0V53.56a9.42,9.42,0,0,0-18.83,0v2.69L64.08,42.8,69,26.28a8.53,8.53,0,1,0-16.37-4.83l-.09.33-3.81-7.24a7.93,7.93,0,0,0-10.51-3.42h0A7.93,7.93,0,0,0,34.73,22l11.5,21.32L36.94,75.08c-4,13.61,1.22,43.15,32.64,43.15S100,94.15,100.75,89.13a40,40,0,0,0-.37-11l.29-.47A10.9,10.9,0,0,0,102.22,72.07Z" style={{ fill: primaryColor, opacity: 0.72}} />
        <g>
          <rect x="8.96" y="18.87" width="8.09" height="8.09" transform="translate(3.65 -1.72) rotate(8.79)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="26.24" y="45.04" width="4.55" height="4.55" style={{fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="22.93" y="102.59" width="6.04" height="6.04" transform="translate(6.07 -1.31) rotate(3.27)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="57.93" y="6.7" width="7.1" height="7.1" transform="translate(51.95 -51.35) rotate(71.74)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="114.12" y="66.19" width="3.77" height="3.77" transform="translate(144.29 -63.42) rotate(71.74)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="112.95" y="20.5" width="6.11" height="6.11" transform="matrix(0.31, 0.95, -0.95, 0.31, 102.02, -93.98)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="113.07" y="89.96" width="10.06" height="10.06" transform="translate(151.2 -52.91) rotate(63.8)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="105.76" y="109.15" width="6.39" height="6.39" transform="translate(161.66 -35.01) rotate(63.8)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="14.17" y="25" width="4.68" height="4.68" transform="translate(33.76 0.46) rotate(63.8)" style={{ fill: primaryColor, fillOpacity: 0.4 }} />
          <rect x="15.46" y="95.97" width="9.75" height="9.75" transform="translate(120.02 75.4) rotate(87.08)" style={{ fill: primaryColor, fillOpacity: 0.6 }} />
          <rect x="84.29" y="27.88" width="6.11" height="6.11" transform="translate(89.35 -61.7) rotate(71.74)" style={{ fill: primaryColor, fillOpacity: 0.16 }} />
          <rect x="26" y="39.74" width="6.11" height="6.11" transform="translate(15.09 -7.01) rotate(18.51)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="26.9" y="33.99" width="7.76" height="7.76" transform="translate(31.25 -10.93) rotate(39.65)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="82.42" y="33.62" width="9.76" height="9.76" transform="translate(130.11 -46.77) rotate(92.86)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
        </g>
      </g>
      <g>
        <path d="M39.94,76.24c1.1-6,11.91-9.23,15.89-11s6.48-.55,9.59.07,6.11,1.51,8.8,1.51,4.71,1.59,4.71,3.55c0,7.09-7.27,8.92-10.93,8.92S63.34,77,62.31,77s-2.27,1.71-4.53,4.4-2.87,3.79-2.44,6.54c.73-2.14,4.95-4.64,6.29-6C74.77,84.24,78,94.27,80.58,99a18.68,18.68,0,0,0-1.41-9.53c2.33-6,5.44-6.91,9.6-7.89-4.46-2.5-10.51,1.65-11.12,5,0,0-2.75-3.67-6.12-4.83,3.24-.12,6.36-1.84,7.34-4,4-.12,5.62-2.81,6.6-4.4a10.63,10.63,0,0,1-5.93,1c.55-.49.55-1,.55-5.13s-5.74-4.16-5.74-4.16a102.44,102.44,0,0,1,.36-11.12c.49-3.27-.32-7.14,3-9.2a9.39,9.39,0,0,0-6.26,8.83v2.69l0-.06-.13,5.44a28.15,28.15,0,0,1-6.19-.49c-3.43-.65-2.94-13.69-2.94-13.69s-2.93,12.22-3.75,14.18-5.21,2.77-11.24,5.54-3.75,0-3.75,0S54.14,28.38,56.09,23s1.79-7.32,7.16-7.32a8.53,8.53,0,0,0-10.63,5.77l-.09.33,0-.07L47.62,38.16S41.18,26.42,38.9,22s-3.85-8-2-10.07a7.94,7.94,0,0,0-2.15,10l11.5,21.32L36.94,75.08c-4,13.61,1.22,43.15,32.64,43.15H70C47.16,118,38.84,82.21,39.94,76.24Z" style={{ fill: "url(#b)", fillOpacity: 0.56 }} />
        <path d="M90.24,56.45V73.2a5.91,5.91,0,0,0,2.77,5,5,5,0,0,0,6.09-.67,7.73,7.73,0,0,0,1.1-1.3c-1.1,3-2.18,5.19-6,5.19s-5-.89-6.12-3.42-.78-5.14,0-8.4S90.24,62.57,90.24,56.45Z" style={{ fill: "url(#b)", fillOpacity: 0.56 }} />
      </g>
    </svg>

  )
}