/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomWinnerFieldValue,
    CustomWinnerFieldValueFromJSON,
    CustomWinnerFieldValueFromJSONTyped,
    CustomWinnerFieldValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface WinnerPayload
 */
export interface WinnerPayload {
    /**
     * 
     * @type {Array<CustomWinnerFieldValue>}
     * @memberof WinnerPayload
     */
    customFields: Array<CustomWinnerFieldValue>;
    /**
     * 
     * @type {string}
     * @memberof WinnerPayload
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WinnerPayload
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof WinnerPayload
     */
    surname: string;
}

export function WinnerPayloadFromJSON(json: any): WinnerPayload {
    return WinnerPayloadFromJSONTyped(json, false);
}

export function WinnerPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): WinnerPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customFields': ((json['customFields'] as Array<any>).map(CustomWinnerFieldValueFromJSON)),
        'email': json['email'],
        'givenName': json['givenName'],
        'surname': json['surname'],
    };
}

export function WinnerPayloadToJSON(value?: WinnerPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customFields': ((value.customFields as Array<any>).map(CustomWinnerFieldValueToJSON)),
        'email': value.email,
        'givenName': value.givenName,
        'surname': value.surname,
    };
}


