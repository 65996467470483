import { Card, LinearProgress } from "@mui/material"
import { Box } from "@mui/system"
import { CenterFrame } from "./CenterFrame"

interface LoadingSpinnerProps {
  error: boolean;
  text: string;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {

  return (
    <CenterFrame>
      <Card sx={{
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        height: 234,
        justifyContent: "space-around",
        maxWidth: "90vw",
        padding: "36px",
        width: "646px"
      }}>
        <Box sx={{
          color: "#656569",
          fontSize: "28px",
          fontWeight: "bold",
          textAlign: "center",
          textTransform: "uppercase"
        }}>
          {!props.error && props.text}
          {props.error && "Leider ist ein Fehler aufgetreten"}
        </Box>
        {!props.error && <LinearProgress /> }
      </Card>
    </CenterFrame>
  )
}