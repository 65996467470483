import { Box, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { CenterFrame } from "../common/CenterFrame";
import { Logo } from "../common/Logo";
import { RichText } from "../common/RichText";
import { IconAddress } from "../icons/IconAddress";
import { IconCheckmark } from "../icons/IconCheckmark";
import { useOptician } from "../optician_loader/OpticianTheme";
import { DiscountForm } from "./DiscountForm";
import {useEffect} from "react";

export const OfferDiscount = () => {
  const optician = useOptician();
  const { codeId } = useParams<{ codeId: string }>();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CenterFrame>
      <Paper sx={{
        alignItems: "center",
        boxShadow: "0px 24px 56px #00000029",
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        width: "100%"
      }}>
        <Logo />
        <IconCheckmark primaryColor={optician?.design?.primaryColor ?? "#000000"} />
        <Box sx={{ maxWidth: "676px" }}>
          <RichText text={optician?.raffle?.preparePriceMessage ?? ""} centered={true} />
        </Box>
        <Box sx={{
          backgroundColor: "#F7F7F7",
          border: "1px solid #E7EBEC",
          borderRadius: "8px",
          boxSizing: "border-box",
          marginTop: "35px",
          textAlign: "center",
          width: "100%"
        }}>
          <Box sx={{ transform: "translateY(-12px)" }}>
            <IconAddress primaryColor={optician?.design?.primaryColor ?? "#000000"} />
          </Box>
          <b>{optician?.name}</b><br />
          {optician?.streetAndNumber}<br />
          {optician?.city}<br />
          <Box sx={{
            padding: "24px"
          }}>
          {optician?.discount?.active &&
            <DiscountForm  discount={optician.discount} raffleCodeId={codeId} />
          }
          </Box>
        </Box>
      </Paper>
    </CenterFrame>
  )
}