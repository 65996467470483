/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
    Winner,
    WinnerFromJSON,
    WinnerFromJSONTyped,
    WinnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface RaffleCode
 */
export interface RaffleCode {
    /**
     * 
     * @type {string}
     * @memberof RaffleCode
     */
    codeNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RaffleCode
     */
    id: string;
    /**
     * 
     * @type {Price}
     * @memberof RaffleCode
     */
    price?: Price;
    /**
     * 
     * @type {Date}
     * @memberof RaffleCode
     */
    usedAt?: Date;
    /**
     * 
     * @type {Winner}
     * @memberof RaffleCode
     */
    usedBy?: Winner;
}

export function RaffleCodeFromJSON(json: any): RaffleCode {
    return RaffleCodeFromJSONTyped(json, false);
}

export function RaffleCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RaffleCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeNumber': !exists(json, 'codeNumber') ? undefined : json['codeNumber'],
        'id': json['id'],
        'price': !exists(json, 'price') ? undefined : PriceFromJSON(json['price']),
        'usedAt': !exists(json, 'usedAt') ? undefined : (new Date(json['usedAt'])),
        'usedBy': !exists(json, 'usedBy') ? undefined : WinnerFromJSON(json['usedBy']),
    };
}

export function RaffleCodeToJSON(value?: RaffleCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeNumber': value.codeNumber,
        'id': value.id,
        'price': PriceToJSON(value.price),
        'usedAt': value.usedAt === undefined ? undefined : (value.usedAt.toISOString()),
        'usedBy': WinnerToJSON(value.usedBy),
    };
}


