import { useEffect, useState } from "react";
import {Box, CircularProgress} from "@mui/material";
import { RichText } from "../common/RichText";

interface LegalTextProps {
  fetchText: () => Promise<string | undefined>;
}

export const LegalText = (props: LegalTextProps) => {
  const { fetchText } = props;

  const [text, setText] = useState<string>();
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchText().then(t => {
      if (t === undefined) {
        setError(true)
      } else {
        setText(t)
      }
    })
  }, [fetchText, setText]);

  if (error) {
    return <Box>Text konnte nicht geladen werden</Box>
  }

  if (text === null) {
    return <CircularProgress />
  }

  return (
    <RichText text={text ?? ""} />
  )
}