import { IconProps } from "./IconProps";

export const IconAddress = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 90" cx="24" cy="40.85" r="15.26" gradientTransform="translate(0 27.95) scale(1 0.32)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.4"/>
          <stop offset="0.12" stop-opacity="0.29"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="b" data-name="Unbenannter Verlauf 97" x1="10.76" y1="4.71" x2="30.34" y2="24.29" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </linearGradient>
      </defs>
      <ellipse cx="24" cy="40.85" rx="15.66" ry="4.99" style={{ fill: "url(#a)" }} />
      <path d="M24.71,2.18A14,14,0,0,0,10,16.15C10,26,18.74,34.64,22.41,37.84a2.41,2.41,0,0,0,3.18,0C29.23,34.66,37.9,26.07,38,16.28A13.88,13.88,0,0,0,24.71,2.18ZM24,19.68a3.54,3.54,0,1,1,3.53-3.53A3.53,3.53,0,0,1,24,19.68Z" style={{ fill: props.primaryColor }}/>
      <path d="M24.71,2.18A14,14,0,0,0,10,16.15C10,26,18.74,34.64,22.41,37.84a2.41,2.41,0,0,0,3.18,0C29.23,34.66,37.9,26.07,38,16.28A13.88,13.88,0,0,0,24.71,2.18ZM24,19.68a3.54,3.54,0,1,1,3.53-3.53A3.53,3.53,0,0,1,24,19.68Z" style={{ fillOpacity: 0.56, fill: "url(#b)" }}/>
    </svg>
  )
}