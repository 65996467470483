import { useOptician } from "../optician_loader/OpticianTheme";


export const IconPriceSend = () => {
  const optician = useOptician();
  const primaryColor = optician!.design!.primaryColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="136" height="136" viewBox="0 0 136 136">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 90" cx="-94.15" cy="22.15" fx="-113.2847543002281" r="38.27" gradientTransform="translate(210.45 112.23) scale(1.56 0.32)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.4"/>
          <stop offset="0.12" stop-opacity="0.29"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="b" data-name="Unbenannter Verlauf 99" x1="78.22" y1="120.94" x2="237.77" y2="120.94" gradientTransform="translate(-34.7 -45.61)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </linearGradient>
        <linearGradient id="c" x1="105.86" y1="53.07" x2="105.86" y2="98.31" gradientTransform="translate(-59.39 -18.43) rotate(-13.12)" xlinkHref="#b"/>
        <linearGradient id="d" data-name="Unbenannter Verlauf 101" x1="94.88" y1="131.44" x2="107.39" y2="79.83" gradientTransform="translate(-59.39 -18.43) rotate(-13.12)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.4"/>
          <stop offset="1" stop-opacity="0"/>
        </linearGradient>
      </defs>
      <ellipse cx="63.76" cy="119.34" rx="62.79" ry="12.3" style={{ fill: "url(#a)" }} />
      <g>
        <path d="M22.93,46.4h96.46a0,0,0,0,1,0,0v54.43a3.45,3.45,0,0,1-3.45,3.45H26.37a3.45,3.45,0,0,1-3.45-3.45V46.4a0,0,0,0,1,0,0Z" transform="translate(-15.24 18.11) rotate(-13.12)" style={{ fill: "#f2f2f2" }} />
        <polygon points="111.56 36.21 56.41 12.05 17.62 58.1 111.56 36.21" style={{ fill: "#f2f2f2" }} />
        <path d="M22.93,46.4h96.46a0,0,0,0,1,0,0v54.43a3.45,3.45,0,0,1-3.45,3.45H26.37a3.45,3.45,0,0,1-3.45-3.45V46.4a0,0,0,0,1,0,0Z" transform="translate(-15.24 18.11) rotate(-13.12)" style={{ fill: "url(#b)", fillOpacity: 0.56 }} />
        <path d="M111.56,36.21,61.21,14.15A7,7,0,0,0,53,16.06l-35.41,42Z" style={{ fill: "url(#c)", fillOpacity: 0.56 }}/>
      </g>
      <g>
        <path d="M104.72,38.18l1.86-.81L104,26.27a2.64,2.64,0,0,0-3.17-2L22,42.67a2.64,2.64,0,0,0-2,3.17l2.59,11.1,2-.1a11.11,11.11,0,0,1,4.9.88L71.22,75.6l29.49-34.47A11.24,11.24,0,0,1,104.72,38.18Z" style={{ fill: "#fff" }} />
        <path d="M104.72,38.18l1.86-.81L104,26.27a2.64,2.64,0,0,0-3.17-2L22,42.67a2.64,2.64,0,0,0-2,3.17l2.59,11.1,2-.1a11.11,11.11,0,0,1,4.9.88L71.22,75.6l29.49-34.47A11.24,11.24,0,0,1,104.72,38.18Z" style={{ fill: "url(#d)", fillOpacity: 0.56 }} />
        <g>
          <polygon points="56.82 69.45 63.03 72.11 77 68.86 80.71 64.52 77.12 49.1 53.37 54.64 56.82 69.45" style={{ fill: "#fff" }} />
          <polygon points="56.82 69.45 63.03 72.11 77 68.86 80.71 64.52 77.12 49.1 53.37 54.64 56.82 69.45" style={{ fill: primaryColor, opacity: 0.8 }} />
          <path d="M71.44,40.23a4,4,0,0,0-3.7.42,12.43,12.43,0,0,0-3.95,5A12.59,12.59,0,0,0,58,42.91a4,4,0,0,0-3.5,1.26,3.33,3.33,0,0,0-.88,3c.58,2.49,3.56,3,5.4,2.59l3.62-.84,3.57-.84,3.62-.84c1.83-.43,4.27-2.22,3.69-4.71A3.3,3.3,0,0,0,71.44,40.23ZM58.57,47.68c-.86.2-2.57,0-2.81-1a1.28,1.28,0,0,1,.36-1.07,2,2,0,0,1,1-.56,1.57,1.57,0,0,1,.61,0,9.91,9.91,0,0,1,4.21,1.86Zm10.81-2.52L66,45.94a9.9,9.9,0,0,1,3-3.53,1.89,1.89,0,0,1,1.69-.16,1.2,1.2,0,0,1,.79.79C71.7,44.06,70.23,45,69.38,45.16Z" style={{ fill: primaryColor }} />
          <rect x="51.2" y="46.95" width="26.96" height="4.98" transform="translate(-9.53 15.97) rotate(-13.12)" style={{ fill: "#fff" }} />
          <rect x="51.2" y="46.95" width="26.96" height="4.98" transform="translate(-9.53 15.97) rotate(-13.12)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="64.64" y="46.3" width="4.43" height="25.01" transform="translate(-11.6 16.71) rotate(-13.12)" style={{ fill: "#fff" }} />
          <rect x="64.64" y="46.3" width="4.43" height="25.01" transform="translate(-11.6 16.71) rotate(-13.12)" style={{ fill: primaryColor, fillOpacity: 0.56 }} />
        </g>
      </g>
    </svg>

  )
}