import { useOptician } from "../optician_loader/OpticianTheme";


export const IconPriceWon = () => {
  const optician = useOptician();
  const primaryColor = optician!.design!.primaryColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="136" height="136" viewBox="0 0 136 136">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 90" cx="-21.35" cy="128.06" fx="-45.192136510213984" r="30.57" gradientTransform="translate(71.73 85.04) scale(1.34 0.32)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.4"/>
          <stop offset="0.12" stop-opacity="0.29"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="b" data-name="Unbenannter Verlauf 25" x1="96.14" y1="91.72" x2="43.3" y2="72.96" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fff"/>
          <stop offset="0.43" stop-color="#fff" stop-opacity="0"/>
          <stop offset="0.53" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </linearGradient>
        <linearGradient id="c" x1="9.73" y1="76.01" x2="52.44" y2="120.45" xlinkHref="#b"/>
      </defs>
      <ellipse cx="43.13" cy="126.17" rx="43.13" ry="9.83" style={{ fill: "url(#a)" }} />
      <g>
        <path d="M79.3,101.93h0c-4.75,1.8-17.15-5.52-29.12-17.5a107.12,107.12,0,0,1-8.05-8.94h0c-7-8.75-10.8-16.62-9.45-20.18h0L10.52,122.11a1.55,1.55,0,0,0,2,2Z" style={{ fill: "#fff" }} />
        <g>
          <path d="M46.23,84.32c-1.56-3-2.93-6-4.11-8.84h0c-7-8.75-10.8-16.62-9.45-20.18h0l-6.29,19a91.67,91.67,0,0,0,8.24,21.66,98.1,98.1,0,0,0,11,17.06l17.18-5.6.27-.09C58,103.29,51.67,94.85,46.23,84.32Z"  style={{ fill: primaryColor, opacity: 0.4 }} />
          <path d="M23,107.53c-1.71-3.3-3.2-6.56-4.45-9.68l-8,24.26a1.55,1.55,0,0,0,2,2l17-5.62A110.13,110.13,0,0,1,23,107.53Z" style={{ fill: primaryColor, opacity: 0.4 }} />
          <path d="M48.33,74.26s-1.48-11.81.06-17.09C53.47,39.69,70.85,42.3,68.29,23c-.72-5.44-3.67-13.71-5-15.23L53.32,14s3.83,5.76,7.75,11.79c5.28,8.1,10.88,17.28.25,27A44.72,44.72,0,0,0,48.33,74.26Z" style={{ fill: primaryColor, fillOpacity: 0.56 }} />
          <rect x="23.32" y="10.04" width="6.11" height="6.11" transform="translate(4.94 -7.09) rotate(16.88)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="35.85" y="36.54" width="6.11" height="6.11" transform="translate(42.05 -15.82) rotate(47.74)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="99.69" y="99.16" width="6.11" height="6.11" transform="matrix(0.55, 0.83, -0.83, 0.55, 130.94, -39.95)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="72.03" y="3.66" width="6.11" height="6.11" transform="translate(68.44 -68.38) rotate(79.83)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="95.45" y="74.71" width="6.11" height="6.11" transform="translate(157.65 -32.93) rotate(79.83)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="118.37" y="106.84" width="6.11" height="6.11" transform="translate(188.15 -39.66) rotate(71.9)"style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="26.39" y="12.36" width="6.11" height="6.11" transform="translate(34.95 -17.37) rotate(71.9)" style={{ fill: primaryColor, fillOpacity: 0.4 }} />
          <rect x="95.45" y="96.2" width="6.11" height="6.11" transform="translate(206.24 10.1) rotate(95.18)" style={{ fill: primaryColor, fillOpacity: 0.6 }} />
          <rect x="110.43" y="12.36" width="6.11" height="6.11" transform="translate(108.61 -99.01) rotate(79.83)" style={{ fill: primaryColor, fillOpacity: 0.16 }} />
          <path d="M50.71,79.86a50.51,50.51,0,0,0,7.41-5.34C69.85,64.17,69,55.16,80,42.51c12.07-13.8,37.77-14.9,45.53-12.42L121.06,43a42.49,42.49,0,0,0-33.87,8.57c-10.07,7.89-17,21.6-28.82,27.36C55.87,80.14,50.71,79.86,50.71,79.86Z" style={{ fill: primaryColor, fillOpacity: 0.8 }} />
          <path d="M55.07,81c5.6,1.73,10.32-1.72,13.53-3.27l.76-.35C68.3,76.19,67.2,75,66,73.7a34.8,34.8,0,0,1-7.65,5.23c-2.5,1.21-7.66.93-7.66.93a50.51,50.51,0,0,0,7.41-5.34,42.78,42.78,0,0,0,4.35-4.43,108.15,108.15,0,0,0-9-7.86,42.11,42.11,0,0,0-5.18,12S47.05,64,48.11,58.37c-7-4.52-12.61-6.32-14.8-4.13-3.68,3.68,3.87,17.19,16.86,30.18S76.67,105,80.35,101.28C82.63,99,80.59,93,75.64,85.6,70.53,87.91,65.3,87.59,55.07,81Z" style={{ fill: primaryColor }} />
          <path d="M55.07,81c5.6,1.73,10.32-1.72,13.53-3.27,6.47-3.13,14.31-5.31,21.48-3.36,6,1.63,12.34,9.85,18.51,11.35,6.75,1.65,11.28-3.18,17-6.74l-2,13.91c-4.26,4-20.08,2.92-31.81-7.17-4.33-3.73-9.93-3.38-15-.72C71.29,87.93,66.07,88.08,55.07,81Z" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <path d="M79.29,101.92c-4.75,1.8-17.15-5.52-29.12-17.5a107.12,107.12,0,0,1-8.05-8.94h0c1.18,2.86,2.55,5.83,4.11,8.84,5.44,10.53,11.78,19,16.84,23l16.23-5.37Z" style={{ fill: primaryColor, fillOpacity: 0.9 }} />
          <path d="M45.62,113a98.1,98.1,0,0,1-11-17.06,91.67,91.67,0,0,1-8.24-21.66L18.56,97.85c1.25,3.12,2.74,6.38,4.45,9.68a110.13,110.13,0,0,0,6.44,10.92l16.22-5.38Z" style={{ fill: primaryColor, fillOpacity: 0.9 }} />
          <rect x="37.39" y="32.23" width="6.11" height="6.11" transform="translate(39.36 -18.38) rotate(47.74)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="57.63" y="24.19" width="6.11" height="6.11" transform="translate(98.96 -27.16) rotate(100.96)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <path d="M48.23,3.42a13.21,13.21,0,0,0-6.32,2.06,5.36,5.36,0,0,0-2,3,7.94,7.94,0,0,0-.18,1.81l.07.89a3.07,3.07,0,0,0,.31,1,3.72,3.72,0,0,0,1.44,1.55c.71.36,1.1.5,1.58.74l.69.33a3,3,0,0,1,.58.3,1.67,1.67,0,0,1,.57.79,1.55,1.55,0,0,1-.43,1.69,8.1,8.1,0,0,1-1.17.64L42,19l-.72.35-.36.18a2.68,2.68,0,0,0-1,3.87,3,3,0,0,0,1.35,1.08c.23.1.46.2.66.31l.41.23-.43.36a4.49,4.49,0,0,0-1.22,4.86,4.29,4.29,0,0,0,1.62,2.06l.46.27s.11,0,.15.08a1.26,1.26,0,0,1,.26.21,1.46,1.46,0,0,1,.18,1.56,1.91,1.91,0,0,1-.29.43c-.2.21-.4.42-.59.64a12.45,12.45,0,0,0-1,1.43,7.34,7.34,0,0,0-1.12,3.28.2.2,0,0,0,.08.17.18.18,0,0,0,.25-.07,9.73,9.73,0,0,1,2-2.38A11,11,0,0,1,43.82,37l.64-.43a4,4,0,0,0,.83-.74,4.08,4.08,0,0,0,.43-4.58,3.72,3.72,0,0,0-.8-1,3.33,3.33,0,0,0-.51-.4.84.84,0,0,1-.22-.18.91.91,0,0,1-.27-.52A1.13,1.13,0,0,1,44.27,28c.09-.09.44-.37.66-.6a5.86,5.86,0,0,0,.78-1,2.76,2.76,0,0,0,.38-2,2.94,2.94,0,0,0-1-1.61,6.41,6.41,0,0,0-1.89-1l-.36-.13.51-.31,1.42-.87a7.58,7.58,0,0,0,1.55-1.1,3.93,3.93,0,0,0,.74-4.33,3.69,3.69,0,0,0-1.48-1.69,4.29,4.29,0,0,0-.88-.4l-.81-.31-1.42-.53a1.91,1.91,0,0,1-.82-.72,1.89,1.89,0,0,1-.21-.51l-.13-.72a6.22,6.22,0,0,1,0-1.43,4.11,4.11,0,0,1,1.3-2.46c1.48-1.36,3.64-2,5.71-2.54a.2.2,0,0,0,.13-.2A.17.17,0,0,0,48.23,3.42Zm-6,18.6,0,0,0,0S42.22,22.07,42.27,22Zm.52,3.34,0,0S42.83,25.41,42.79,25.36Z" style={{ fill: primaryColor }} />
          <path d="M82.44,26l1.65-2.55c.56-.83,1.12-1.67,1.69-2.38.32-.42.65-.92.91-1.32l.18-.26.22.1c.31.14.7.4,1.08.62a1.9,1.9,0,0,0,.75.25,1.78,1.78,0,0,0,1.94-1.61h0a9,9,0,0,0-.49-3.78H91a12.59,12.59,0,0,0,3.44-.38,2.36,2.36,0,0,0,1.32-.94,2.71,2.71,0,0,0,.33-1.4,3.58,3.58,0,0,0-.89-2,16.94,16.94,0,0,0-2.45-2.16c-.75-.59-1.73-1.32-1.51-1.53a.8.8,0,0,1,.37-.76,2.4,2.4,0,0,1,1.14-.4c1.89-.28,3.91.31,6,.1a.17.17,0,0,0,0-.33h-.06c-1.92-.11-3.88-1-6.08-.94a3.68,3.68,0,0,0-1.76.4,2.58,2.58,0,0,0-.82.7,2.86,2.86,0,0,0-.38,1l-.08.5a2,2,0,0,0,.18.73,4,4,0,0,0,.57.83c.39.44.77.78,1.14,1.14a15.35,15.35,0,0,1,1.95,2.07,1.13,1.13,0,0,1,.26.61,11.51,11.51,0,0,1-2.61,0c-1-.09-1.87-.24-3.18-.31a1.63,1.63,0,0,0-.53.06,1.47,1.47,0,0,0-1,1.85l.09.27a19.4,19.4,0,0,1,.51,2,2.81,2.81,0,0,0-1.56.34,3,3,0,0,0-1.08,1.08,5.18,5.18,0,0,0-.4.85c-.2.5-.34.89-.54,1.34a17,17,0,0,0-1,3.06,13.19,13.19,0,0,0-.41,3.14.21.21,0,0,0,.09.15A.18.18,0,0,0,82.44,26Zm11.32-13.7c0,.17-.06.18,0,0Zm-6.73,7h0C87.12,19.21,87.18,19.17,87,19.32Z" style={{ fill: primaryColor }} />
          <path d="M118,55.13a3.59,3.59,0,0,0-2,2c-.43.83-.74,1.69-1.17,2.44a3.17,3.17,0,0,1-1.63,1.62,2.45,2.45,0,0,1-2.07-.34,8.65,8.65,0,0,1-1.9-1.61l-.43-.48a5.7,5.7,0,0,0-.54-.59,2.7,2.7,0,0,0-3.81.3,2.89,2.89,0,0,0-.31.43l-.06.11a3,3,0,0,0-.77-1.16,2.62,2.62,0,0,0-1.28-.59,2.5,2.5,0,0,0-1.51.25,3.79,3.79,0,0,0-1.46,1.57c-.27.49-.47.95-.66,1.38l-.15.35-.08-.07L98,60.58a3.48,3.48,0,0,0-.55-.36,3.52,3.52,0,0,0-2.66-.3,3.18,3.18,0,0,0-1.28.71,5,5,0,0,0-.69.79A16.26,16.26,0,0,0,91.23,64c-.16.31-.08.19-.09.23a.09.09,0,0,1,0,0,.23.23,0,0,1-.09.08H91l-.57-.5A7,7,0,0,0,89,63a4.42,4.42,0,0,0-3.66-.05,10.19,10.19,0,0,0-4.15,4.25.2.2,0,0,0,0,.2.19.19,0,0,0,.25,0c1.52-1,3.1-2.21,4.55-2.44a2.06,2.06,0,0,1,1.7.4,4.36,4.36,0,0,1,.74.7c.14.17.17.24.4.5a3.12,3.12,0,0,0,1.07.78,3.33,3.33,0,0,0,2.61,0,3.29,3.29,0,0,0,1.13-.75,4.23,4.23,0,0,0,.67-.92,21.21,21.21,0,0,1,1.36-2.1l.18-.23h0l.3.21.55.38a6.25,6.25,0,0,1,.81.63l.21.2A1.34,1.34,0,0,0,98,65a1.53,1.53,0,0,0,2.09-.55,14,14,0,0,0,1.15-2.87c.13-.41.27-.79.41-1.12a1.46,1.46,0,0,1,.14-.28l.07.21c.18.68.21,1.57.38,2.6l0,.11a1.14,1.14,0,0,0,2.17.27l1.13-2.48.29-.62L106,60c0-.07,0-.1.08-.14a.73.73,0,0,1,.48-.31.77.77,0,0,1,.6.13,3.94,3.94,0,0,1,.41.39l.52.5a10.16,10.16,0,0,0,2.44,1.68,3.81,3.81,0,0,0,3.19.14,4.23,4.23,0,0,0,2-2.3c.38-.88.63-1.77.94-2.6a3.14,3.14,0,0,1,1.47-2h0a.17.17,0,0,0-.12-.32ZM95.76,63.35s0,0,0,0h0S95.77,63.36,95.76,63.35ZM101.69,60c-.07,0-.06-.1,0,0Z" style={{ fill: primaryColor }} />
        </g>
      </g>
      <g>
        <path d="M55.07,81c5.6,1.73,10.32-1.72,13.53-3.27l.76-.35C68.3,76.19,67.2,75,66,73.7a34.8,34.8,0,0,1-7.65,5.23c-2.5,1.21-7.66.93-7.66.93a50.51,50.51,0,0,0,7.41-5.34,42.78,42.78,0,0,0,4.35-4.43,108.15,108.15,0,0,0-9-7.86,42.11,42.11,0,0,0-5.18,12S47.05,64,48.11,58.37c-7-4.52-12.61-6.32-14.8-4.13-3.68,3.68,3.87,17.19,16.86,30.18S76.67,105,80.35,101.28C82.63,99,80.59,93,75.64,85.6,70.53,87.91,65.3,87.59,55.07,81Z" style={{ fill: "url(#b)", fillOpacity: 0.56 }} />
        <path d="M79.3,101.93h0c-4.75,1.8-17.15-5.52-29.12-17.5a107.12,107.12,0,0,1-8.05-8.94h0c-7-8.75-10.8-16.62-9.45-20.18h0L10.52,122.11a1.55,1.55,0,0,0,2,2Z" style={{ fill: "url(#c)", fillOpacity: 0.56 }} />
      </g>
    </svg>
  )
}