import { CircularProgress, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { decodeError, useApi } from "../common/ApiProvider";
import { Discount } from "../gen/api"
import { useOptician } from "../optician_loader/OpticianTheme";
import { DiscountInput } from "./DiscountInput";
import { SendProgress } from "./SendProgress";

interface DiscountFormProps {
  discount: Discount;
  raffleCodeId: string;
}

export const DiscountForm = (props: DiscountFormProps) => {
  
  const { discount, raffleCodeId } = props;
  const optician = useOptician();
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [sendState, setSendState] = useState<"notSent" | "sending" | "sent">("notSent");

  if (sendState === "sending") {
    <CircularProgress />
  }

  const sendDiscount = useCallback((givenName: string, surname: string, email: string) => {
    api.sendToFriend({
      raffleCodeId: raffleCodeId,
      discountFriend: {
        givenName,
        surname,
        email
      }
    }).then(() => {
      setSendState("sent");
    }).catch(decodeError(errorCode => {
      setSendState("notSent");
      switch (errorCode) {
        case "ALREADY_USED":
          enqueueSnackbar("Dieser Rabattcode wurde bereits verwendet", { variant: "error" });
          break;
        default:
          enqueueSnackbar("Die Mail konnte leider nicht gesendet werden", { variant: "error" });
      }
    }));
    setSendState("sending");
  }, [api, raffleCodeId, enqueueSnackbar]);

  return (
    <Box sx={{
      alignItems: "center",
      background: `${optician?.design?.primaryColor} linear-gradient(124deg, #0000007B 0%, #000000D6 100%)`,
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "40px",
      width: "100%"
    }}>
      <Typography variant="h5" sx={{
        color: "#FFFFFFA2",
        fontSize: "14px",
        fontWeight: "bold",
        paddingTop: "80px",
        textTransform: "uppercase"
      }}>
        {sendState === "notSent" && "Jetzt sind Sie dran"}
        {sendState === "sending" && "Einen Moment bitte"}
        {sendState === "sent" && "Erledigt"}
      </Typography>
      <Typography variant="h3" sx={{
        color: "white",
        fontSize: "32px",
        fontWeight: "bold",
        paddingTop: "8px",
        textAlign: "center",
        textTransform: "uppercase"
      }}>
        {sendState === "notSent" && "Gutschein verschenken"}
        {sendState === "sending" && "Geschenk wird versendet"}
        {sendState === "sent" && "Gutschein wurde versendet"}
      </Typography>
      {sendState === "notSent" &&
        <DiscountInput discount={discount} sendDiscount={sendDiscount} />
      }
      {sendState !== "notSent" && 
        <SendProgress state={sendState} />
      }
    </Box>
  )
}