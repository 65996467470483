import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { RichText } from "../common/RichText";
import { Price } from "../gen/api";


interface PriceViewProps {
  price: Price;
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#F7F7F7",
  border: "1px solid #E7EBEC",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  width: "100%"
}))

export const PriceView = (props: PriceViewProps) => {
  return (
    <Container>
      {props.price.imageUrl &&
        <Box sx={{
          height: "252px",
          maxWidth: "100%",
          margin: "24px"
        }}>
          <img style={{ height: "100%", maxWidth: "100%", objectFit: "contain" }} src={props.price.imageUrl} alt="Preis" />
        </Box>
      }
      <Box sx={{
        margin: "24px"
      }}>
        <Typography variant="h3" sx={{
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "center",
          textTransform: "uppercase"
        }}>{props.price.title}</Typography>
        <Typography variant="body1" sx={{
          fontSize: "16px"
        }}><RichText text={props.price.description ?? ""} centered={true} /></Typography>
      </Box>
    </Container>
  )
}