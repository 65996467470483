import { Dialog } from "@mui/material"
import { Box, styled } from "@mui/system"
import { PropsWithChildren } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useOptician } from "../optician_loader/OpticianTheme";

export const Heading = styled("h4")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "24px",
  fontWeight: "bold",
  hyphens: "auto",
  textTransform: "uppercase"
}));

export const Paragraph = styled("p")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "16px"
}));

const CloseButton = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "4px",
  color: "white",
  cursor: "pointer",
  display: "flex",
  height: "65px",
  justifyContent: "center",
  width: "65px"
}))

interface TextDialogProps {
  open: boolean;
  onClose: () => void;
}

export const TextDialog = (props: PropsWithChildren<TextDialogProps>) => {
  const optician = useOptician();

  const buttonColor = optician?.design?.buttonColor ?? "#000000";

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <Box sx={{
        backgroundColor: "#F7F7F7",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}>
        <Box sx={{
          overflow: "auto",
          flexGrow: 1,
          padding: "min(80px, 4vw)"
        }}>
          {props.children}
        </Box>
        <Box sx={{
          margin: "auto",
          marginBottom: "12px",
          marginTop: "12px",
        }}>
          <CloseButton onClick={props.onClose}>
            <ClearIcon sx={{ color: buttonColor }} />
          </CloseButton>
        </Box>
      </Box>
    </Dialog>
  )
}