/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Winner
 */
export interface Winner {
    /**
     * 
     * @type {string}
     * @memberof Winner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Winner
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof Winner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Winner
     */
    surname?: string;
}

export function WinnerFromJSON(json: any): Winner {
    return WinnerFromJSONTyped(json, false);
}

export function WinnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Winner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'id': json['id'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
    };
}

export function WinnerToJSON(value?: Winner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'givenName': value.givenName,
        'id': value.id,
        'surname': value.surname,
    };
}


