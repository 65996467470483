/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldType,
    FieldTypeFromJSON,
    FieldTypeFromJSONTyped,
    FieldTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomWinnerFieldPayload
 */
export interface CustomWinnerFieldPayload {
    /**
     * 
     * @type {FieldType}
     * @memberof CustomWinnerFieldPayload
     */
    fieldType: FieldType;
    /**
     * 
     * @type {string}
     * @memberof CustomWinnerFieldPayload
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomWinnerFieldPayload
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomWinnerFieldPayload
     */
    placeholder: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomWinnerFieldPayload
     */
    required: boolean;
}

export function CustomWinnerFieldPayloadFromJSON(json: any): CustomWinnerFieldPayload {
    return CustomWinnerFieldPayloadFromJSONTyped(json, false);
}

export function CustomWinnerFieldPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomWinnerFieldPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldType': FieldTypeFromJSON(json['fieldType']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'placeholder': json['placeholder'],
        'required': json['required'],
    };
}

export function CustomWinnerFieldPayloadToJSON(value?: CustomWinnerFieldPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldType': FieldTypeToJSON(value.fieldType),
        'id': value.id,
        'name': value.name,
        'placeholder': value.placeholder,
        'required': value.required,
    };
}


