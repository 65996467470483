import { IconPriceSend } from "../icons/IconPriceSend"
import { IconPriceSent } from "../icons/IconPriceSent"


interface SendProgressProps {
  state: "sending" | "sent";
}

export const SendProgress = (props: SendProgressProps) => {

  if (props.state === "sending") {
    return (
      <IconPriceSend />
    )
  }

  return (
    <IconPriceSent />
  )
}