/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomWinnerField,
    CustomWinnerFieldFromJSON,
    CustomWinnerFieldFromJSONTyped,
    CustomWinnerFieldToJSON,
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
    Optician,
    OpticianFromJSON,
    OpticianFromJSONTyped,
    OpticianToJSON,
    OpticianDesign,
    OpticianDesignFromJSON,
    OpticianDesignFromJSONTyped,
    OpticianDesignToJSON,
    OpticianDetailedAllOf,
    OpticianDetailedAllOfFromJSON,
    OpticianDetailedAllOfFromJSONTyped,
    OpticianDetailedAllOfToJSON,
    Raffle,
    RaffleFromJSON,
    RaffleFromJSONTyped,
    RaffleToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpticianDetailed
 */
export interface OpticianDetailed {
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    streetAndNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDetailed
     */
    zip?: string;
    /**
     * 
     * @type {Array<CustomWinnerField>}
     * @memberof OpticianDetailed
     */
    customWinnerFields?: Array<CustomWinnerField>;
    /**
     * 
     * @type {OpticianDesign}
     * @memberof OpticianDetailed
     */
    design?: OpticianDesign;
    /**
     * 
     * @type {Discount}
     * @memberof OpticianDetailed
     */
    discount?: Discount;
    /**
     * 
     * @type {Raffle}
     * @memberof OpticianDetailed
     */
    raffle?: Raffle;
}

export function OpticianDetailedFromJSON(json: any): OpticianDetailed {
    return OpticianDetailedFromJSONTyped(json, false);
}

export function OpticianDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpticianDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': !exists(json, 'city') ? undefined : json['city'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'streetAndNumber': !exists(json, 'streetAndNumber') ? undefined : json['streetAndNumber'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'customWinnerFields': !exists(json, 'customWinnerFields') ? undefined : ((json['customWinnerFields'] as Array<any>).map(CustomWinnerFieldFromJSON)),
        'design': !exists(json, 'design') ? undefined : OpticianDesignFromJSON(json['design']),
        'discount': !exists(json, 'discount') ? undefined : DiscountFromJSON(json['discount']),
        'raffle': !exists(json, 'raffle') ? undefined : RaffleFromJSON(json['raffle']),
    };
}

export function OpticianDetailedToJSON(value?: OpticianDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'streetAndNumber': value.streetAndNumber,
        'zip': value.zip,
        'customWinnerFields': value.customWinnerFields === undefined ? undefined : ((value.customWinnerFields as Array<any>).map(CustomWinnerFieldToJSON)),
        'design': OpticianDesignToJSON(value.design),
        'discount': DiscountToJSON(value.discount),
        'raffle': RaffleToJSON(value.raffle),
    };
}


