import { IconProps } from "./IconProps";

export const IconCheckmark = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="136" height="136" viewBox="0 0 136 136">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 90" cx="-28.04" cy="22.15" fx="-46.96954151595986" r="30.57" gradientTransform="translate(128.44 112.23) scale(1.71 0.32)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.4"/>
          <stop offset="0.12" stop-opacity="0.29"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="b" data-name="Unbenannter Verlauf 97" x1="57.46" y1="39.87" x2="86.51" y2="68.92" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </linearGradient>
      </defs>
      <ellipse cx="80.62" cy="119.34" rx="54.92" ry="9.83" style={{ fill: "url(#a)" }} />
      <path d="M123.23,28.71l-8.3-8.3a4.35,4.35,0,0,0-6.15,0L49.37,79.82,29.31,59.76a4.35,4.35,0,0,0-6.15,0l-8.29,8.29a4.36,4.36,0,0,0,0,6.16l28.32,28.32a8.76,8.76,0,0,0,12.37,0h0l67.67-67.66A4.36,4.36,0,0,0,123.23,28.71Z" style={{ fill: props.primaryColor }} />
      <path d="M123.23,28.71l-8.3-8.3a4.35,4.35,0,0,0-6.15,0L49.37,79.82,29.31,59.76a4.35,4.35,0,0,0-6.15,0l-8.29,8.29a4.36,4.36,0,0,0,0,6.16l28.32,28.32a8.76,8.76,0,0,0,12.37,0h0l67.67-67.66A4.36,4.36,0,0,0,123.23,28.71Z" style={{ fillOpacity: 0.56, fill: "url(#b)" }} />
    </svg>
  )
}