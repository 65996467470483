/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    expiresAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    sessionToken: string;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresAt': (new Date(json['expiresAt'])),
        'refreshToken': json['refreshToken'],
        'sessionToken': json['sessionToken'],
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiresAt': (value.expiresAt.toISOString()),
        'refreshToken': value.refreshToken,
        'sessionToken': value.sessionToken,
    };
}


