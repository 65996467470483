/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegalTexts
 */
export interface LegalTexts {
    /**
     * 
     * @type {string}
     * @memberof LegalTexts
     */
    dataPrivacy?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalTexts
     */
    imprint?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalTexts
     */
    participation?: string;
}

export function LegalTextsFromJSON(json: any): LegalTexts {
    return LegalTextsFromJSONTyped(json, false);
}

export function LegalTextsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalTexts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataPrivacy': !exists(json, 'dataPrivacy') ? undefined : json['dataPrivacy'],
        'imprint': !exists(json, 'imprint') ? undefined : json['imprint'],
        'participation': !exists(json, 'participation') ? undefined : json['participation'],
    };
}

export function LegalTextsToJSON(value?: LegalTexts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataPrivacy': value.dataPrivacy,
        'imprint': value.imprint,
        'participation': value.participation,
    };
}


