import { Box } from "@mui/system";
import parseHtml from "html-react-parser";
import { useOptician } from "../optician_loader/OpticianTheme";

interface RichTextProps {
  text: string;
  centered?: boolean;
  whiteText?: boolean;
}

export const RichText = (props: RichTextProps) => {
  const { text, centered, whiteText } = props;
  const optician = useOptician();
  const headlineColor = optician?.design?.headlineColor;

  return (
    <Box sx={{
      "& *": {
        color: whiteText ? "white" : undefined
      },
      "& h1": {
        color: headlineColor
      },
      "& h2": {
        color: headlineColor
      },
      "& h3": {
        color: headlineColor
      },
      textAlign: centered ? "center" : "left"
    }}>
      {parseHtml(text)}
    </Box>
  )
}