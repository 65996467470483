import { useOptician } from "../optician_loader/OpticianTheme";


export const IconPriceSent = () => {
  const optician = useOptician();
  const primaryColor = optician!.design!.primaryColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="136" height="136" viewBox="0 0 136 136">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 90" cx="-150.28" cy="22.15" fx="-169.41482507817724" r="38.27" gradientTransform="translate(302.91 112.23) scale(1.56 0.32)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.4"/>
          <stop offset="0.12" stop-opacity="0.29"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="b" data-name="Unbenannter Verlauf 109" x1="15.34" y1="91.33" x2="37.13" y2="85.51" gradientTransform="translate(19.29 -13.57) rotate(13.12)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fff" stop-opacity="0"/>
          <stop offset="1" stop-color="#fff" stop-opacity="0.6"/>
        </linearGradient>
        <linearGradient id="c" data-name="Unbenannter Verlauf 99" x1="78.77" y1="159.67" x2="238.32" y2="159.67" gradientTransform="translate(-30.25 -84.34)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </linearGradient>
      </defs>
      <ellipse cx="68.76" cy="119.34" rx="62.79" ry="12.3" style={{ fill: "url(#a)" }} />
      <g>
        <rect x="12.7" y="48.15" width="111.89" height="57.87" transform="translate(-15.7 17.59) rotate(-13.12)" style={{ fill: "url(#b)", fillOpacity: 0.56 }} />
        <rect x="27.93" y="46.4" width="96.46" height="57.87" rx="3.45" transform="translate(-15.11 19.25) rotate(-13.12)" style={{ fill: "#f2f2f2" }} />
        <rect x="27.93" y="46.4" width="96.46" height="57.87" rx="3.45" transform="translate(-15.11 19.25) rotate(-13.12)" style={{ fill: "url(#c)", fillOpacity: 0.56 }} />
      </g>
      <g>
        <rect x="39.71" y="75.77" width="43.38" height="20.91" rx="2.43" transform="translate(-17.97 16.18) rotate(-13.12)" style={{ fill: "#fff" }} />
        <rect x="42.21" y="78.27" width="38.38" height="15.91" rx="0.69" transform="translate(140.78 156.26) rotate(166.88)"  style={{ fill: primaryColor, opacity: 0.8 }} />
      </g>
    </svg>
  )
}