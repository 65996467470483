import { Box } from "@mui/system"
import { PropsWithChildren } from "react"
import { ImageRepeat } from "../gen/api";
import { useOptician } from "../optician_loader/OpticianTheme"


export const Background = (props: PropsWithChildren<{}>) => {
  const optician = useOptician();
  const color = optician?.design?.backgroundColor;
  const image = optician?.design?.backgroundImageUrl;
  const repeat = optician?.design?.backgroundImageRepeat === ImageRepeat.Repeat;

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh"
    }}>
      <Box sx={{
        backgroundColor: color,
        backgroundImage: image ? `url(${image})` : undefined,
        backgroundPosition: "center center",
        backgroundSize: repeat ? undefined : "cover",
        backgroundRepeat: repeat ? "repeat" : "no-repeat",
        minHeight: "100vh",
        position: "fixed",
        width: "100%",
        zIndex: -1
      }} />
      {props.children}
    </Box>
  )
}