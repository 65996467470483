import { Box, Paper } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApiProvider } from './common/ApiProvider';
import { BottomBar } from './bottom_bar/BottomBar';
import { CenterFrame } from './common/CenterFrame';
import { OpticianDetailed } from './gen/api';
import { OpticianLoader } from './optician_loader/OpticianLoader';
import { OpticianTheme } from './optician_loader/OpticianTheme';
import { Background } from './common/Background';

function App() {

  const [optician, setOptician] = useState<OpticianDetailed | null>(null);

  return (
    <BrowserRouter>
      <SnackbarProvider resumeHideDuration={0}>
        <OpticianTheme optician={optician}>
          <Background>
            <ApiProvider>
              <Switch>
                <Route path="/:opticianSlug">
                  <OpticianLoader optician={optician} setOptician={setOptician} />
                </Route>
                <Route path="">
                  <CenterFrame>
                    <Paper sx={{fontSize: "20px", padding: "24px"}}>
                      <Box >
                        Um am Gewinnspiel teilzunehmen, geben Sie bitte die vollständige URL in die Adressleiste Ihres Browsers ein.<br/><br/>
                        Beispiel: optikergewinnspiel.de/IHR-OPTIKER
                      </Box>
                    </Paper>
                  </CenterFrame>
                </Route>
              </Switch>
              <BottomBar />
            </ApiProvider>
          </Background>
        </OpticianTheme>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
