/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountFriend
 */
export interface DiscountFriend {
    /**
     * 
     * @type {string}
     * @memberof DiscountFriend
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountFriend
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountFriend
     */
    surname: string;
}

export function DiscountFriendFromJSON(json: any): DiscountFriend {
    return DiscountFriendFromJSONTyped(json, false);
}

export function DiscountFriendFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountFriend {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'givenName': json['givenName'],
        'surname': json['surname'],
    };
}

export function DiscountFriendToJSON(value?: DiscountFriend | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'givenName': value.givenName,
        'surname': value.surname,
    };
}


