import {Card} from "@mui/material";
import {Box} from "@mui/system";
import {CenterFrame} from "../common/CenterFrame";
import Lottie from "react-lottie-player";
import {OpticianDetailed} from "../gen/api";

export const Waiting = (props: {
  optician: OpticianDetailed | undefined
}) => {
  const {optician} = props;

  return (
    <CenterFrame>
      <Card sx={{
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        height: "60vh",
        justifyContent: "space-around",
        maxWidth: "90vw",
        padding: "36px",
        width: "646px"
      }}>
        <Box sx={{
          color: "#656569",
          display: "flex",
          fontSize: "28px",
          fontWeight: "bold",
          justifyContent: "center",
          textAlign: "center",
          textTransform: "uppercase"
        }}>
          <Lottie
            play
            path={optician?.design?.waitingAnimation ?? "/lottie/gift-and-confetti.json"}
            style={{width: 200, height: 200}}
          />
        </Box>
      </Card>
    </CenterFrame>
  )
}
