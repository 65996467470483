/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegalTextsPayload
 */
export interface LegalTextsPayload {
    /**
     * 
     * @type {string}
     * @memberof LegalTextsPayload
     */
    dataPrivacy: string;
    /**
     * 
     * @type {string}
     * @memberof LegalTextsPayload
     */
    imprint: string;
    /**
     * 
     * @type {string}
     * @memberof LegalTextsPayload
     */
    participation: string;
}

export function LegalTextsPayloadFromJSON(json: any): LegalTextsPayload {
    return LegalTextsPayloadFromJSONTyped(json, false);
}

export function LegalTextsPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalTextsPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataPrivacy': json['dataPrivacy'],
        'imprint': json['imprint'],
        'participation': json['participation'],
    };
}

export function LegalTextsPayloadToJSON(value?: LegalTextsPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataPrivacy': value.dataPrivacy,
        'imprint': value.imprint,
        'participation': value.participation,
    };
}


