import { useOptician } from "../optician_loader/OpticianTheme";


export const IconPriceError = () => {
  const optician = useOptician();
  const primaryColor = optician!.design!.primaryColor;
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136">
      <defs>
        <radialGradient id="a" data-name="Unbenannter Verlauf 93" cx="-22.93" cy="129.61" r="30.57" gradientTransform="translate(98.17 58.33) scale(1.32 0.48)" gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stop-opacity="0.4"/>
          <stop offset="0.17" stop-opacity="0.31"/>
          <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="b" data-name="Unbenannter Verlauf 74" x1="49.61" y1="70" x2="49.61" y2="54.32" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.6"/>
          <stop offset="1" stop-opacity="0.9"/>
        </linearGradient>
        <linearGradient id="c" x1="86.39" y1="70" x2="86.39" y2="54.32" xlinkHref="#b"/>
        <radialGradient id="d" data-name="Unbenannter Verlauf 63" cx="28.97" cy="31.17" r="104.55" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fff"/>
          <stop offset="0.02" stop-color="#fff" stop-opacity="0.98"/>
          <stop offset="0.05" stop-color="#fff" stop-opacity="0.91"/>
          <stop offset="0.09" stop-color="#fff" stop-opacity="0.8"/>
          <stop offset="0.13" stop-color="#fff" stop-opacity="0.64"/>
          <stop offset="0.17" stop-color="#fff" stop-opacity="0.45"/>
          <stop offset="0.22" stop-color="#fff" stop-opacity="0.21"/>
          <stop offset="0.25" stop-color="#fff" stop-opacity="0"/>
          <stop offset="0.53" stop-opacity="0"/>
          <stop offset="1" stop-opacity="0.8"/>
        </radialGradient>
        <radialGradient id="e" data-name="Unbenannter Verlauf 64" cx="68.67" cy="77" r="17.73" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.16"/>
          <stop offset="1" stop-opacity="0.8"/>
        </radialGradient>
        <radialGradient id="f" data-name="Unbenannter Verlauf 66" cx="81.25" cy="42.5" r="19.43" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-opacity="0.24"/>
          <stop offset="1"/>
        </radialGradient>
        <radialGradient id="g" cx="33.25" cy="39.5" r="15.38" xlinkHref="#f"/>
      </defs>
      <g>
        <ellipse cx="68" cy="120.22" rx="42.38" ry="14.61" style={{ fill: "url(#a)" }} />
        <circle cx="68" cy="68.94" r="41.83" style={{ fill: "#fff", opacity: 0.8  }} />
      </g>
      <g>
        <g>
          <rect x="24.45" y="13.77" width="8.09" height="8.09" transform="translate(53.94 39.78) rotate(-171.21)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="93.53" y="7.59" width="4.55" height="4.55" transform="translate(191.62 19.72) rotate(180)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="122.98" y="27.92" width="6.04" height="6.04" transform="translate(250.03 69.02) rotate(-176.73)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="110.73" y="45.48" width="7.1" height="7.1" transform="translate(103.53 172.92) rotate(-108.26)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="15.33" y="52.43" width="3.77" height="3.77" transform="translate(-28.96 87.68) rotate(-108.26)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="14.16" y="95.77" width="6.11" height="6.11" transform="translate(-71.24 146.15) rotate(-108.26)" style={{ fill: primaryColor, fillOpacity: 0.24 }} />
          <rect x="16.82" y="42.29" width="10.06" height="10.06" transform="translate(-10.96 87.81) rotate(-116.2)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
          <rect x="107.56" y="47.69" width="6.39" height="6.39" transform="translate(113.98 172.72) rotate(-116.2)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="22.64" y="11.04" width="4.68" height="4.68" transform="translate(24.01 41.71) rotate(-116.2)" style={{ fill: primaryColor, fillOpacity: 0.4 }} />
          <rect x="44.17" y="20.53" width="6.11" height="6.11" transform="translate(8.04 58.96) rotate(-68.61)" style={{ fill: primaryColor, fillOpacity: 0.16 }} />
          <rect x="92.21" y="11.32" width="6.11" height="6.11" transform="translate(181.04 58.26) rotate(-161.49)" style={{ fill: primaryColor, fillOpacity: 0.08 }} />
          <rect x="112.39" y="88.53" width="9.76" height="9.76" transform="translate(-30.83 116.73) rotate(-47.49)" style={{ fill: primaryColor, fillOpacity: 0.7 }} />
        </g>
        <circle cx="68" cy="68.94" r="41.83" style={{ fill: primaryColor, opacity: 0.72 }} />
        <circle cx="49.61" cy="60.31" r="13.11" style={{ fill: "#fff" }} />
        <circle cx="86.39" cy="60.31" r="13.11" style={{ fill: "#fff" }} />
        <path d="M40.55,71.38a2.87,2.87,0,0,1,1.65-1.76c.78-.29.94.12,1.44.59,1.63,1.54,3-.81,4.84-.5.72.12,1.07.74,1.74,1,.93.31,1.63-.37,2.54-.29a2.6,2.6,0,0,0,1,0,6.84,6.84,0,0,1,1.29-.24c.75,0,2,.27,1.5,1.3s-2.06,1-2.84,1.6a8.54,8.54,0,0,0-2.57,4.34c-.54,2,.48,3.82.89,6.33.26,1.62,0,4.41-1.94,5.08A3.25,3.25,0,0,1,47,88.12,3.43,3.43,0,0,1,46.09,85c.25-2.48.47-4.41.92-6.8.4-2.16-1.44-3-3.23-3.21a4.81,4.81,0,0,1-2-.44A3.68,3.68,0,0,1,40.55,71.38Z" style={{ fill: "#e8e8e8", opacity: 0.71 }} />
        <path d="M95.47,70.46c0-.68-1.85-1.91-2.75-1.29-.46.32-.2,1-.9,1.39s-1.22,0-1.94-.23A3.26,3.26,0,0,0,88,70.15c-1.06.33-.81.66-1.95.55-.3,0-.39-.32-.8-.3s-.47.45-.8.52a2.59,2.59,0,0,1-2.15-.34c-.8-.35-3.28-1.7-3.47-.1-.11.91,1,1.18,1.47,1.64s.87,1.22,1.42,1.79c1.36,1.43,2.14,1,3.7.58s2.81.7,4.17,1.42c.92.48,1.68,1.1,2.68.41s.56-1.36.94-2.21C93.73,72.85,96.28,72.57,95.47,70.46Z" style={{ fill: "#e8e8e8", opacity: 0.71 }} />
      </g>
      <g>
        <path d="M49.24,50.89a9.51,9.51,0,0,0-9,8.71,9.41,9.41,0,0,0,6.3,9.59.92.92,0,0,0,1.23-1,4.71,4.71,0,0,1,.11-1.74A4.82,4.82,0,0,1,51.65,63a4.73,4.73,0,0,1,4.67,1.87.94.94,0,0,0,1.6-.08A9.33,9.33,0,0,0,59,60.31,9.42,9.42,0,0,0,49.24,50.89Zm-3.19,10.8a2.83,2.83,0,1,1,2.83-2.82A2.83,2.83,0,0,1,46.05,61.69Zm4.12-6.11a1.3,1.3,0,1,1,1.3-1.29A1.29,1.29,0,0,1,50.17,55.58Z" style={{ fill: "url(#b)" }} />
        <path d="M86,50.89a9.52,9.52,0,0,0-9,8.71,9.41,9.41,0,0,0,6.3,9.59.92.92,0,0,0,1.23-1,4.94,4.94,0,0,1,.12-1.74A4.81,4.81,0,0,1,88.43,63a4.75,4.75,0,0,1,4.68,1.87.94.94,0,0,0,1.59-.08,9.33,9.33,0,0,0,1.11-4.43A9.42,9.42,0,0,0,86,50.89Zm-3.18,10.8a2.83,2.83,0,1,1,2.82-2.82A2.83,2.83,0,0,1,82.84,61.69ZM87,55.58a1.3,1.3,0,1,1,1.3-1.29A1.29,1.29,0,0,1,87,55.58Z" style={{ fill: "url(#c)" }}/>
      </g>
      <g>
        <circle cx="68" cy="68.94" r="41.83" style={{ fill: "url(#d)", fillOpacity: 0.56 }} />
        <path d="M68,88a18.54,18.54,0,0,1,11.09,3.67c1.18.86,2.52.66,1.75-.71C78,85.91,73.29,82.61,68,82.61S58,85.91,55.16,91c-.77,1.37.57,1.57,1.75.71A18.54,18.54,0,0,1,68,88Z" style={{ fill: "url(#e)", fillOpacity: 0.56 }} />
        <path d="M86,42.86a7.31,7.31,0,0,1,4.4-1.24,9.92,9.92,0,0,1,7.82,4,7.29,7.29,0,0,1,1.56,4.3l-.22.17A18.54,18.54,0,0,0,86,43.14Z" style={{ fill: "url(#f)", fillOpacity: 0.56 }} />
        <path d="M50.07,43.14A18.54,18.54,0,0,0,36.5,50.08l-.22-.17a7.24,7.24,0,0,1,1.57-4.3,9.88,9.88,0,0,1,7.81-4,7.36,7.36,0,0,1,4.41,1.24Z"  style={{ fill: "url(#g)", fillOpacity: 0.56 }} />
      </g>
    </svg>
  )
}