/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageRepeat,
    ImageRepeatFromJSON,
    ImageRepeatFromJSONTyped,
    ImageRepeatToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpticianDesignPayload
 */
export interface OpticianDesignPayload {
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    backgroundColor: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    backgroundImageRef?: string;
    /**
     * 
     * @type {ImageRepeat}
     * @memberof OpticianDesignPayload
     */
    backgroundImageRepeat: ImageRepeat;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    bodyFont: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    bodyFontFileRef?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    buttonColor: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    fontColor: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    headerFont: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    headerFontFileRef?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    headlineColor: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    logoRef?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    primaryColor: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesignPayload
     */
    waitingAnimationRef?: string;
    /**
     * 
     * @type {number}
     * @memberof OpticianDesignPayload
     */
    waitingTime?: number;
}

export function OpticianDesignPayloadFromJSON(json: any): OpticianDesignPayload {
    return OpticianDesignPayloadFromJSONTyped(json, false);
}

export function OpticianDesignPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpticianDesignPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backgroundColor': json['backgroundColor'],
        'backgroundImageRef': !exists(json, 'backgroundImageRef') ? undefined : json['backgroundImageRef'],
        'backgroundImageRepeat': ImageRepeatFromJSON(json['backgroundImageRepeat']),
        'bodyFont': json['bodyFont'],
        'bodyFontFileRef': !exists(json, 'bodyFontFileRef') ? undefined : json['bodyFontFileRef'],
        'buttonColor': json['buttonColor'],
        'fontColor': json['fontColor'],
        'headerFont': json['headerFont'],
        'headerFontFileRef': !exists(json, 'headerFontFileRef') ? undefined : json['headerFontFileRef'],
        'headlineColor': json['headlineColor'],
        'logoRef': !exists(json, 'logoRef') ? undefined : json['logoRef'],
        'primaryColor': json['primaryColor'],
        'waitingAnimationRef': !exists(json, 'waitingAnimationRef') ? undefined : json['waitingAnimationRef'],
        'waitingTime': !exists(json, 'waitingTime') ? undefined : json['waitingTime'],
    };
}

export function OpticianDesignPayloadToJSON(value?: OpticianDesignPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backgroundColor': value.backgroundColor,
        'backgroundImageRef': value.backgroundImageRef,
        'backgroundImageRepeat': ImageRepeatToJSON(value.backgroundImageRepeat),
        'bodyFont': value.bodyFont,
        'bodyFontFileRef': value.bodyFontFileRef,
        'buttonColor': value.buttonColor,
        'fontColor': value.fontColor,
        'headerFont': value.headerFont,
        'headerFontFileRef': value.headerFontFileRef,
        'headlineColor': value.headlineColor,
        'logoRef': value.logoRef,
        'primaryColor': value.primaryColor,
        'waitingAnimationRef': value.waitingAnimationRef,
        'waitingTime': value.waitingTime,
    };
}


