import { Box, styled } from "@mui/system";
import { useOptician } from "../optician_loader/OpticianTheme";

export const TopImageBox = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  height: "172px",
  justifyContent: "center",
  maxWidth: "60vw",
  padding: "32px min(111px, 10vw)",
  width: "424px",
  "& img": {
    margin: "auto"
  }
}))

export const Logo = () => {
  const optician = useOptician();
  const logoUrl = optician?.design?.logoUrl;

  if (!logoUrl) {
    return <></>
  }

  return (
    <TopImageBox>
      <img src={logoUrl} alt="Logo" style={{ height: "min(200px, 60vh)", width: "min(400px, 60vw)", objectFit: "contain" }} />
    </TopImageBox>
  )
}
